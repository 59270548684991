// useSocket.js
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io("https://fundeia.pt", {
      path: '/socket.io',
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd"
      }
    });

    setSocket(newSocket);

    // Clean up the socket connection on unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  return socket;
};

export default useSocket;