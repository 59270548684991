import "./Navbar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth.context";
import { BiSearch, BiSolidMessageSquareDetail, BiSolidBell, BiSolidSearch, BiSolidWallet } from 'react-icons/bi';
import { AiFillHome } from 'react-icons/ai';
import axios from "axios";

function Navbar() {
  // Subscribe to the AuthContext to gain access to
  // the values from AuthContext.Provider's `value` prop
  const { isLoggedIn, user, logOutUser } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const unreadNotificationsCount = notifications?.filter(notification => !notification.read).length || 0;

  const location = useLocation();

  useEffect(() => {
    setSearchQuery('');
  }, [location.pathname]);

  const handleNotificationClick = (notification, url, projectId) => {
    navigate(url, { state: { projectId } });
    closeAllMenus();
    if (notification.read) return;
    readNotification(notification._id);
  };

  const readNotification = async (notificationId) => {
    try {
      await axios.patch(`https://fundeia.pt/server/user/notificationRead/${user._id}`, { notificationId }, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } });
      getUser();
    } catch (error) {
      console.log(error);
    }
  };

  const toggleNotificationMenu = () => {
    closeAllMenus();
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const getUser = async () => {
    const userId = user._id
    try {
      const response = await axios.get(`https://fundeia.pt/server/user/${userId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } });
      setUserDetails(response.data);
      setNotifications(response.data.notifications);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      getUser();

      // Set up an interval to fetch notifications every minute (60000 ms)
      const notificationInterval = setInterval(() => {
        getUser();
      }, 30000);

      // Clear the interval when the component is unmounted or when the user logs out
      return () => clearInterval(notificationInterval);
    }
  }, [user]);

  const handleLogout = () => {
    closeAllMenus();
    logOutUser();
    navigate("/login");
  };

  const closeAllMenus = () => {
    setIsProfileMenuOpen(false);
    setIsSearchBarOpen(false);
    setIsNotificationMenuOpen(false);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsNotificationMenuOpen(false);
    if (isSearchBarOpen) { // If the search bar is open, close it
      setIsSearchBarOpen(false);
    }
  };

  const toggleSearchBar = () => {
    setIsSearchBarOpen(!isSearchBarOpen);
    setIsProfileMenuOpen(false);
    setIsNotificationMenuOpen(false);
  };

  const updateSearchQuery = (value) => setSearchQuery(value);

  const initiateSearch = () => {
    closeAllMenus();
    if (location.pathname === '/projects') {
      navigate(`/projects?q=${searchQuery}`);
    } else if (location.pathname === '/mybids') {
      navigate(`/mybids?q=${searchQuery}`);
    } else {
      navigate(`/feed?q=${searchQuery}`);
    }
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className="bg-primary shadow-md h-15 fixed top-0 left-0 w-full z-50">
      <nav className="bg-primary py-2 text-black w-5/6 sm:w-3/5 mx-auto">
        <div className="container mx-auto flex items-center justify-between">

          <div className="flex flex-row items-center w-2/5">
            <div className="w-10 h-10 md:w-12 md:h-12 -ml-2" onClick={closeAllMenus}>
              <Link to="/feed">
                <img src="/fundeia.png" alt="Logo" className="w-full h-full" />
              </Link>
            </div>

            <button className="md:hidden ml-4" onClick={toggleSearchBar}>
              <BiSolidSearch className="w-6 h-6" />
            </button>

            {isSearchBarOpen && (
              <div className="absolute left-20 sm:left-40 mt-[104px] w-2/4 sm:w-2/5 md:hidden bg-white rounded shadow-lg z-10">
                <input
                  type="text"
                  className="py-2 pr-12 pl-4 rounded text-sm focus:outline-none w-full"
                  placeholder="Pesquisar"
                  onChange={(e) => updateSearchQuery(e.target.value)}
                />
                <button className="absolute top-0 right-0 px-2 h-full bg-gray-200 rounded" onClick={initiateSearch}>
                  <BiSearch />
                </button>
              </div>
            )}

            <div className="relative ml-4 w-3/5 lg:w-4/5 md:block hidden">
              <input type="text" className="py-2 pr-12 pl-4 rounded text-sm focus:outline-none w-full" placeholder="Pesquisar" value={searchQuery} onChange={(e) => updateSearchQuery(e.target.value)} />
              <button className="absolute top-0 right-0 px-2 h-full bg-gray-200 rounded" onClick={initiateSearch}>
                <BiSearch />
              </button>
            </div>
          </div>

          {/*         {isLoggedIn && ( */}
          <div className="flex items-center space-x-5 md:space-x-8 mr-2">
            <Link to="/feed" className="flex flex-col items-center" onClick={closeAllMenus}>
              <AiFillHome className="w-6 h-6 md:w-5 md:h-5" />
              <label className="text-sm cursor-pointer font-semibold md:block hidden">Feed</label>
            </Link>

            <div className="relative">
              <button onClick={toggleNotificationMenu} className="flex flex-col items-center">
                <BiSolidBell className="w-6 h-6 md:w-5 md:h-5" />
                {unreadNotificationsCount > 0 && (
                  <div className="absolute -top-2 md:top-0 -right-2 md:right-0 bg-red-500 text-white rounded-full w-5 h-5 text-xs flex items-center justify-center">
                    {unreadNotificationsCount}
                  </div>
                )}
                <label className="text-sm cursor-pointer font-semibold md:block hidden">Notificações</label>
              </button>
              {isNotificationMenuOpen && (
                <div className="absolute -right-20 sm:-right-20 mt-[22px] md:mt-3.5 w-60 bg-white border border-gray-300 rounded shadow-lg z-10 max-h-56 overflow-y-auto">
                  {notifications && notifications.length > 0 ? notifications
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map((notification, index) => (
                      <button
                        onClick={() => handleNotificationClick(notification, notification.url, notification.projectId)}
                        className={`block p-2 text-center ${notification.read ? 'text-gray-400' : 'text-black'} hover:bg-gray-200 justify-center w-full`}
                        key={index}
                      >
                        {notification.text}
                      </button>
                    ))
                    : (<p className="text-center p-2 text-sm">Sem notificações</p>)}
                </div>
              )}
            </div>

            {/* <Link to="/messages" className="flex flex-col items-center" onClick={closeAllMenus}>
              <BiSolidMessageSquareDetail className="w-6 h-6 md:w-5 md:h-5" />
              <label className="text-sm cursor-pointer font-semibold md:block hidden">Mensagens</label>
            </Link> */}
          </div>

          <div className="relative">
            <button onClick={toggleProfileMenu} className="flex items-center">
              <div className="flex flex-col items-center">
                <img src={userDetails.profilePicture} className="w-8 h-8 md:w-8 md:h-8 rounded-full border border-gray-300" alt="profile" />
                <label className="text-sm cursor-pointer font-semibold">{userDetails && userDetails.wallet.toFixed(2)}€</label>
              </div>
            </button>

            {isProfileMenuOpen && (
              <div className="absolute -right-0 sm:-right-12 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-10">
                <Link to="/profile" className="block py-2 text-center text-black hover:bg-gray-200" onClick={closeAllMenus}>
                  Perfil
                </Link>
                <Link to="/projects" className="block py-2 text-center text-black hover:bg-gray-200" onClick={closeAllMenus}>
                  Meus Projetos
                </Link>
                <Link to="/mybids" className="block py-2 text-center text-black hover:bg-gray-200" onClick={closeAllMenus}>
                  Projetos Licitados
                </Link>
                {userDetails.isAdmin === true && <Link to="/info" className="block py-2 text-center text-black hover:bg-gray-200" onClick={closeAllMenus}>
                  Informações
                </Link>}

                <button onClick={handleLogout} className="block w-full text-center py-2 text-black hover:bg-gray-200">
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
