import "./FeedPage.css";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from "../../context/auth.context";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { FaStar, FaStarOfLife, FaRegStar } from 'react-icons/fa';
import Rating from "../../components/Rating/Rating";
import { successToast, errorToast } from '../../components/Toast/Toast';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Countdown from '../../components/Countdown/Countdown';
import Swal from 'sweetalert2';

function FeedPage() {
    const [projects, setProjects] = useState([]);
    const { user } = useContext(AuthContext);
    const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
    const [currentAudioProjectId, setCurrentAudioProjectId] = useState(null);
    const [userWallet, setUserWallet] = useState(0);
    const [projectsListened, setProjectsListened] = useState([]);
    const [projectTextContents, setProjectTextContents] = useState({}); // Separate state for project text contents
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRefs = useRef({});
    const [showMore, setShowMore] = useState({});
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();

    const resetSearch = () => {
        setSearchQuery('');
        setSearchResults([]);
        navigate('/feed');
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('q');
        if (query !== null) {
            setSearchQuery(query);
        }
    }, [location.search]);

    useEffect(() => {
        if (searchQuery) {
            initiateSearch();
        }
    }, [searchQuery]);

    const initiateSearch = async () => {
        try {
            const response = await axios.get('https://fundeia.pt/server/project/searchProjects', {
                params: { q: searchQuery },
                headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error("Houve um erro ao buscar os projetos:", error);
        }
    };

    const navigate = useNavigate();

    const toggleShowMore = (projectId) => {
        setShowMore(prevState => ({ ...prevState, [projectId]: !prevState[projectId] }));
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigateToIndividualProject = (project) => {
        const projectId = project._id;
        navigate(`/individualproject/${project._id}`, { state: { projectId } });
    };

    // Function to play audio for a specific project
    const playAudio = async (project) => {
        const projectId = project._id;
        const audioElement = audioRefs.current[projectId];

        const projectListened = projectsListened.find(project => project.projectId === projectId);

        if (!projectListened && userWallet < 0.10) {
            errorToast("Não tem saldo suficiente para ouvir este áudio");
            return;
        }

        if (project.user !== user._id && (!projectListened)) {
            const { isConfirmed } = await Swal.fire({
                title: 'Confirmação',
                text: `Ao confirmar, será debitado 0.10€ da sua carteira. Deseja continuar?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                confirmButtonColor: '#ffa500'
            });
            if (!isConfirmed) {
                return;
            }
        }

        getUserInfo()

        // Pause any currently playing audio
        for (const id in audioRefs.current) {
            if (audioRefs.current[id] !== audioElement) {
                audioRefs.current[id].pause();
            }
        }

        // Play the selected audio
        setCurrentAudioProjectId(projectId);
        audioElement.currentTime = 0;
        setIsPlaying(true);
        audioElement.play();
    };

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    const pauseAudio = (toastError) => {
        if (isPlaying) {
            audioRefs.current[currentAudioProjectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            audioRefs.current[currentAudioProjectId].pause();
            setCurrentAudioDuration(0);
            setCurrentAudioProjectId(null);
            setIsPlaying(false);
            if (toastError === true) {
                errorToast("O áudio foi pausado pois não tem saldo suficiente para ouvir o resto");
            }
        }
    };

    useEffect(() => {
        if (audioRefs.current[currentAudioProjectId]) {
            audioRefs.current[currentAudioProjectId].addEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
        }

        return () => {
            if (audioRefs.current[currentAudioProjectId]) {
                audioRefs.current[currentAudioProjectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            }
        };
    }, [currentAudioProjectId]);

    useEffect(() => {
        fetchProjects();
        getUserInfo();
    }, []);

    const fetchProjects = () => {
        axios.get('https://fundeia.pt/server/project/allprojects', { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                const openProjects = response.data.filter(project => project.status === 'open');
                setProjects(openProjects);
            })
            .catch(error => {
                console.error("Houve um erro ao buscar os projetos:", error);
            });
    }

    const getUserInfo = () => {
        axios.get(`https://fundeia.pt/server/user/${user._id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                setUserWallet(response.data.wallet);
                setProjectsListened(response.data.projects);
            })
            .catch(error => {
                console.error("Houve um erro ao buscar a carteira do usuário:", error);
            });
    }

    const updateWalletAmount = (charge, minutes) => {
        if (userWallet < charge) {
            pauseAudio(true);
            updateTextContent(currentAudioProjectId, null);
            return;
        }

        axios.patch(
            `https://fundeia.pt/server/user/wallet/${user._id}`,
            { charge: charge, projectId: currentAudioProjectId, minutesListened: minutes },
            { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } }
        )
            .then(response => {
                // Wallet update successful, update the userWallet state
                setUserWallet(response.data.updatedUser.wallet);
                getUserInfo();
                if (minutes === 5) {
                    fetchProjects()
                }
            })
            .catch(error => {
                console.error("Error updating user's wallet:", error);
            });
    };

    let lastMilestoneProcessedTime = 0;
    /*     const bufferTime = 1; // 1 second buffer time */

    let milestonesShown = [];

    const handleAudioTimeUpdate = (e, projectId) => {
        if (currentAudioProjectId === projectId) {
            const currentTime = e.target.currentTime;
            const audioDuration = e.target.duration;
            setCurrentAudioDuration(currentTime);

            const projectListened = projectsListened.find(project => project.projectId === projectId);

            let project = projects.find(p => p._id === projectId);

            if (project.user === user._id) {
                // User is the owner of the project, don't charge
                updateTextContent(currentAudioProjectId, "Dono do projeto não é cobrado");
                return;
            }

            if (projectListened) {
                const { minutesListened } = projectListened;
                if (minutesListened === 1) updateTextContent(currentAudioProjectId, `Já ouviu 1 minuto deste áudio`)
                else if (minutesListened === 5) updateTextContent(currentAudioProjectId, `Este projeto já está desbloqueado`)
                else if (minutesListened === 0) updateTextContent(currentAudioProjectId, `Já começou a ouvir este áudio anteriormente`)
                else updateTextContent(currentAudioProjectId, `Já ouviu ${minutesListened} minutos deste áudio`)

                if (currentTime < minutesListened * 60 + 60) {
                    // User is still within the already listened time
                    return;
                }
            }

            const checkMilestone = async (time, message, amount, walletThreshold) => {
                if (
                    !milestonesShown.some(
                        (milestone) => milestone.projectId === projectId && milestone.time === time
                    )
                ) {
                    if (Math.abs(currentTime - time) < 0.5 && currentTime > lastMilestoneProcessedTime) {
                        if (time !== 0) {
                            e.target.pause();
                            const { isConfirmed } = await Swal.fire({
                                title: 'Confirmação',
                                text: `${amount}€ vão ser deduzidos da sua conta. Deseja continuar?`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não',
                                confirmButtonColor: '#ffa500'
                            });

                            if (!isConfirmed) {
                                pauseAudio();
                                return;
                            }
                        }
                        milestonesShown = [...milestonesShown, { projectId, time }];
                        if (audioDuration > 240 && time > 240) {
                            updateWalletAmount(amount, 5);
                            updateTextContent(currentAudioProjectId, message);
                            e.target.play();
                        } else if (audioDuration > 180 && audioDuration < 240 && time >= 180 && time < 240) {
                            updateWalletAmount(amount, 5);
                            updateTextContent(currentAudioProjectId, message);
                            e.target.play();
                        } else if (audioDuration > 120 && audioDuration < 180 && time >= 120 && time < 180) {
                            updateWalletAmount(amount, 5);
                            updateTextContent(currentAudioProjectId, message);
                            e.target.play();
                        } else if (audioDuration > 60 && audioDuration < 120 && time >= 60 && time < 120) {
                            updateWalletAmount(amount, 5);
                            updateTextContent(currentAudioProjectId, message);
                            e.target.play();
                        } else if (audioDuration > 0 && audioDuration < 60 && time >= 0 && time < 60) {
                            updateWalletAmount(amount, 5);
                            updateTextContent(currentAudioProjectId, message);
                            e.target.play();
                        } else {
                            if (userWallet >= walletThreshold) {
                                updateWalletAmount(amount, time / 60);
                                updateTextContent(currentAudioProjectId, message);
                                e.target.play();
                            } else {
                                pauseAudio(true);
                                updateTextContent(currentAudioProjectId, null);
                            }
                        }

                        lastMilestoneProcessedTime = currentTime;
                    }
                }
            };

            if (audioDuration > 240) {
                checkMilestone(0, "Foi retirado 0.10€ da carteira", 0.10, 0.10);
                checkMilestone(60, "Foi retirado 0.20€ da carteira", 0.20, 0.20);
                checkMilestone(120, "Foi retirado 0.50€ da carteira", 0.50, 0.50);
                checkMilestone(180, "Foi retirado 1.00€ da carteira", 1.00, 1.00);
                checkMilestone(240, "Foi retirado 2.00€ da carteira, atualize a página para ver o projeto desbloqueado", 2.00, 0);
            } else if (audioDuration > 180 && audioDuration < 240) {
                checkMilestone(0, "Foi retirado 0.10€ da carteira", 0.10, 0.10);
                checkMilestone(60, "Foi retirado 0.20€ da carteira", 0.20, 0.20);
                checkMilestone(120, "Foi retirado 0.50€ da carteira", 0.50, 0.50);
                checkMilestone(180, "Foi retirado 1.00€ da carteira, atualize a página para o acesso ser desbloqueado", 1.00, 0);
            } else if (audioDuration > 120 && audioDuration < 180) {
                checkMilestone(0, "Foi retirado 0.10€ da carteira", 0.10, 0.10);
                checkMilestone(60, "Foi retirado 0.20€ da carteira", 0.20, 0.20);
                checkMilestone(120, "Foi retirado 0.50€ da carteira, atualize a página para o acesso ser desbloqueado", 0.50, 0);
            } else if (audioDuration > 60 && audioDuration < 120) {
                checkMilestone(0, "Foi retirado 0.10€ da carteira", 0.10, 0.10);
                checkMilestone(60, "Foi retirado 0.20€ da carteira, atualize a página para o acesso ser desbloqueado", 0.20, 0);
            } else if (audioDuration > 0 && audioDuration < 60) {
                checkMilestone(0, "Foi retirado 0.10€ da carteira, atualize a página para o acesso ser desbloqueado", 0.10, 0);
            }
        } else {
            e.target.pause()
        }
    };

    const updateTextContent = (projectId, textContent) => {
        setProjectTextContents(prevContents => ({
            ...prevContents,
            [projectId]: textContent
        }));
    };

    return (
        <div className="bg-gray-100 pt-[68px]">
            <h1 className="text-2xl pt-4 font-semibold">Feed</h1>
            <div className="containerNavBar flex w-5/6 sm:w-3/5 mx-auto space-x-4">
                <div className="w-full sm:w-2/4 md:w-3/4 mt-2">
                    {searchQuery && (
                        <>
                            <button className="my-4 bg-primary rounded-md hover:bg-primary-hover p-2 text-white text-base font-bold" onClick={resetSearch}>Limpar Pesquisa</button>
                            <p className="mb-4 text-base sm:text-xl font-bold">Estes são os resultados da pesquisa:</p>
                        </>
                    )}
                    {projects
                        .filter(project =>
                            project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            project.description.toLowerCase().includes(searchQuery.toLowerCase())
                        ).length === 0 ? (
                        <>
                            <p className="font-semibold text-sm sm:text-base">Não foram encontrados resultados para a sua pesquisa</p>
                        </>
                    ) : (
                        projects
                            .filter(project =>
                                project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                project.description.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            .sort((a, b) => {
                                const getLastBidTimestamp = (bids) => {
                                    if (bids.length === 0) return 0;
                                    return bids.reduce((prev, current) => (prev.timestamp > current.timestamp ? prev : current), { timestamp: 0 }).timestamp;
                                };
                                const lastBidTimestampA = new Date(getLastBidTimestamp(a.bids) || a.createdAt);
                                const lastBidTimestampB = new Date(getLastBidTimestamp(b.bids) || b.createdAt);
                                return lastBidTimestampB - lastBidTimestampA;
                            })
                            .map(project => (
                                <div
                                    key={project._id}
                                    className={`my-4 py-5 md:py-8 rounded-md shadow-lg ${project.user === user._id ? 'bg-gray-200' : 'bg-white'}`}>
                                    <div className="relative mx-5 md:mx-8">
                                        <div className="icon-container top-right absolute -right-2 -top-2"> {/* Use absolute positioning */}
                                            {projectsListened.some(p => p.projectId === project._id) && projectsListened.find(p => p.projectId === project._id).minutesListened === 5 && (
                                                <FaLockOpen />
                                            )}
                                        </div>
                                        <div className='flex flex-row items-center mb-4'>
                                            <img src={project.projectPicture} alt="project" className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full" />
                                            <div className='flex flex-col ml-3 justify-between'>
                                                <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>
                                                {isMobile && project.averageRating > 0 &&
                                                    <div className='self-start items-center'>
                                                        <Rating rating={project.averageRating} />
                                                    </div>}
                                            </div>
                                            {!isMobile && project.averageRating > 0 &&
                                                <div className={`ml-auto self-start ${projectsListened.some(p => p.projectId === project._id) && projectsListened.find(p => p.projectId === project._id).minutesListened === 5 ? 'mt-4' : 'mt-0.5'} flex flex-row items-center`}>
                                                    <Rating rating={project.averageRating} />
                                                </div>}
                                        </div>
                                        <div className="text-justify text-sm">
                                            <p>
                                                {showMore[project._id]
                                                    ? project.description
                                                    : project.description.length <= (isMobile ? 120 : 350)
                                                        ? project.description
                                                        : `${project.description.substring(0, isMobile ? 120 : 350)}...`
                                                }
                                            </p>
                                            {(showMore[project._id] || project.description.length > (isMobile ? 120 : 350)) &&
                                                <button
                                                    className="text-primary text-sm"
                                                    onClick={() => toggleShowMore(project._id)}
                                                    style={{ display: 'block', margin: '5px auto' }}
                                                >
                                                    {showMore[project._id] ? 'Mostrar Menos' : 'Mostrar Mais'}
                                                </button>
                                            }
                                        </div>

                                        <div>
                                            {project.bids && project.bids.length > 0 && project.status === "open" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão a decorrer</p>
                                                : project.status === "closed" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão fechado</p> : null}
                                            {project.closingTime && project.status != 'closed' && <Countdown closingTime={project.closingTime} status={project.status} id={project._id} />}
                                            <p className="text-black my-2 text-sm md:text-base"><strong>Valor Pretendido:</strong> {project.desiredValue}€</p>
                                        </div>

                                        <div className="flex justify-center align-center mt-4">
                                            {currentAudioProjectId === project._id && isPlaying ? (
                                                <button className="pause-button font-bold" onClick={() => pauseAudio()}>
                                                    Pausar áudio
                                                </button>
                                            ) : (
                                                <button className="play-button font-bold" onClick={() => playAudio(project)}>
                                                    Reproduzir áudio
                                                </button>
                                            )}
                                            <audio ref={el => (audioRefs.current[project._id] = el)}>
                                                <source src={project.audioUrl} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                        {currentAudioProjectId === project._id && (
                                            <div className="mt-4 bg-gray-100 w-4/5 md:w-3/5 px-6 pt-6 pb-4 mx-auto rounded-md">
                                                <div className="relative h-4 rounded-md bg-gray-300 mx-auto">
                                                    <div className="h-full bg-primary rounded-md " style={{ width: `${(currentAudioDuration / audioRefs.current[project._id].duration) * 100}%`, minWidth: '2%' }}></div>
                                                </div>
                                                <p className="text-black text-sm mt-2">
                                                    {formatTime(currentAudioDuration)} / {formatTime(audioRefs.current[project._id].duration)}
                                                </p>
                                                {currentAudioProjectId === project._id && (
                                                    <div className="text-center text-sm mt-2 text-black">
                                                        <p>{projectTextContents[project._id] ? projectTextContents[project._id] : 'Ao minuto 1 irão ser retirados 0.20€'}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {((projectsListened.some(p => p.projectId === project._id) && projectsListened.find(p => p.projectId === project._id).minutesListened === 5) || (user?._id === project?.user)) && (
                                            <div className="flex justify-end"><button
                                                className="px-3 py-3 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                                onClick={() => navigateToIndividualProject(project)}
                                            >
                                                <AiOutlineArrowRight size={18} />
                                            </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                            ))}

                </div>
                <div className="w-1/4 hidden md:block">
                    <div className={`py-4 px-2 mt-6 rounded-md shadow-lg bg-white`}>
                        <h2 className="text-lg font-semibold mb-2">Taxas</h2>
                        <div className="space-y-2">
                            <p className="text-sm lg:text-sm"><strong>Minuto 0:</strong> 0.10€</p>
                            <p className="text-sm lg:text-sm"><strong>Minuto 1:</strong> 0.20€</p>
                            <p className="text-sm lg:text-sm"><strong>Minuto 2:</strong> 0.50€</p>
                            <p className="text-sm lg:text-sm"><strong>Minuto 3:</strong> 1.00€</p>
                            <p className="text-sm lg:text-sm"><strong>Minuto 4:</strong> 2.00€</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedPage;
