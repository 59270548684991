import React from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { BsStarHalf } from 'react-icons/bs';

const Rating = ({ rating }) => {
  const maxStars = 5;
  const fullStars = Math.floor(rating);
  const remainingRating = rating - fullStars ; // Decimal part of the rating

  let hasHalfStar = false;

  if (remainingRating >= 0.25 && remainingRating < 0.75) {
    hasHalfStar = true;
  }

  const stars = Array.from({ length: maxStars }, (_, index) => {
    if (index < fullStars) {
      return (
        <FaStar
          key={index}
          name="star"
          className="w-4 h-4"
          color="rgb(255 165 0 / var(--tw-bg-opacity))"
        />
      );
    } else if (hasHalfStar && index === fullStars) {
      return (
        <BsStarHalf
          key={index}
          name="star-half"
          className="w-4 h-4"
          color="rgb(255 165 0 / var(--tw-bg-opacity))"
        />
      );
    } else if (index === fullStars && remainingRating >= 0.75) {
      return (
        <FaStar
          key={index}
          name="star"
          className="w-4 h-4"
          color="rgb(255 165 0 / var(--tw-bg-opacity))"
        />
      );
    } else {
      return (
        <FaRegStar
          key={index}
          name="star"
          className="w-4 h-4"
          color="rgb(255 165 0 / var(--tw-bg-opacity))"
        />
      );
    }
  });

  return <div className="rating">{stars}</div>;
};

export default Rating;
