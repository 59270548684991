import "./HomePage.css";

function HomePage() {
  return (
    <div>
      <h1>Home page</h1>
    </div>
  );
}

export default HomePage;
