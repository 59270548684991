import "./IndividualProjectPage.css";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from "../../context/auth.context";
import { IoMdSend } from 'react-icons/io';
import { BsFillChatSquareDotsFill, BsFillPauseFill, BsFillPlayFill, BsThreeDotsVertical } from 'react-icons/bs';
import { FaStar, FaRegStar } from 'react-icons/fa';
import useSocket from './socket';
import Loading from "../../components/Loading/Loading";
import Rating from "../../components/Rating/Rating";
import { successToast, errorToast } from '../../components/Toast/Toast';
import { AiFillFilePdf } from 'react-icons/ai';
import { FiUser, FiPhone } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { BiSolidChevronDownCircle } from 'react-icons/bi';
import { BsHouseDoor } from 'react-icons/bs';

function IndividualProjectPage() {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [project, setProject] = useState({});
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const { user } = useContext(AuthContext);
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);
  const [ratings, setRatings] = useState(3);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
  const [currentAudioTime, setCurrentAudioTime] = useState(0);
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const socket = useSocket();
  const [isLoading, setIsLoading] = useState(true);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [bidAmount, setBidAmount] = useState('');
  const userId = user._id;
  const [userWallet, setUserWallet] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showAudioLeftSide, setShowAudioLeftSide] = useState(window.innerWidth < 1024);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isReviewVisible, setIsReviewVisible] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [showAllBids, setShowAllBids] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const messageContainerRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const isProjectClosedRef = useRef(false);  // use a ref instead of state

  const intervalIdRef = useRef();

  useEffect(() => {
    if (project.closingTime && project.status !== 'closed') {
      const updateCountdown = () => {
        const now = new Date();
        const endTime = new Date(project.closingTime);
        const distance = endTime - now;

        if (distance <= 0) {
          clearInterval(intervalIdRef.current);  // access intervalId here via ref
          closeProject();
          return;
        }

        const hours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setTimeLeft(`${hours}H ${minutes}M ${seconds}S`);
      };

      updateCountdown(); // update immediately
      intervalIdRef.current = setInterval(updateCountdown, 1000);  // assign value to intervalId here via ref

      return () => {
        clearInterval(intervalIdRef.current);
        isProjectClosedRef.current = false;  // reset the ref value on unmount, if needed
      };
    }
  }, [project.closingTime]);

  const closeProject = () => {
    if (isProjectClosedRef.current) return;
    const highestBid = project.bids.sort((a, b) => b.amount - a.amount)[0];

    const winner = highestBid.user._id
    const status = 'closed'

    axios.patch(`https://fundeia.pt/server/project/winnerselection/${projectId}`, { status: status, winner: winner }, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
      },
    })
      .then(response => {
        successToast('Projeto fechou!');
        console.log('ddsds')
        axios.patch(`https://fundeia.pt/server/user/winNotification/${winner}`, { text: "Ganhaste o leilão", projectId: project._id, url: `individualproject/${project._id}` }, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          }
        })
        fetchProject();
        isProjectClosedRef.current = true;
      })
      .catch(error => {
        errorToast('Houve um erro ao fechar o projeto:', error);
      });
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      const handleScroll = () => {
        const element = messageContainerRef.current;
        if (element) {
          if (element.scrollTop + element.clientHeight < element.scrollHeight - 50) {
            setShowArrow(true);
          } else {
            setShowArrow(false);
          }
        }
      };

      const element = messageContainerRef.current;
      if (element) {
        element.addEventListener('scroll', handleScroll);
        // Call the handleScroll function to check the initial state
        handleScroll();
      }

      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [messageContainerRef.current]);

  useEffect(() => {
    if (chatContainerRef.current) {
      const handleScroll = () => {
        const element = chatContainerRef.current;
        if (element) {
          if (element.scrollTop + element.clientHeight < element.scrollHeight - 50) {
            setShowArrow(true);
          } else {
            setShowArrow(false);
          }
        }
      };

      const element = chatContainerRef.current;
      if (element) {
        element.addEventListener('scroll', handleScroll);
        // Call the handleScroll function to check the initial state
        handleScroll();
      }

      return () => {
        if (element) {
          element.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [chatContainerRef.current]);

  const navigate = useNavigate();

  const navigateToMyProjectPage = () => {
    navigate(`/myproject/${projectId}`, { state: { projectId } });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setShowAudioLeftSide(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeChat = () => {
    setChatModalIsOpen(false);
    markMessagesAsRead();
  };

  const toggleShowMore = () => {
    setShowMore(prevState => !prevState);
  };

  const submitBid = async () => {
    try {
      setIsSubmitting(true);

      if (bidAmount === '' || bidAmount === 0 || bidAmount === null || bidAmount === undefined) {
        errorToast("Insira um valor válido");
        return;
      }

      const bid = parseFloat(bidAmount).toFixed(2);

      if (bid < 10) {
        errorToast("O valor da licitação tem que ser superior a 10,00€");
        return;
      }

      const previousHighestBidder = project.bids[0]?.user._id;

      if (bid <= project.bids[0]?.amount) {
        errorToast("O valor da licitação tem que ser superior à licitação mais alta");
        return;
      }

      if (bid > userWallet) {
        errorToast("Não tem saldo suficiente para licitar o valor inserido");
        return;
      }

      const response = await axios.patch(`https://fundeia.pt/server/project/bid/${projectId}`, { bid, user: userId }, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
      });

      if (response.status === 200) {
        successToast("Licitação submetida com sucesso!");
        setBidAmount('');
        fetchProject();

        // Notify the previous highest bidder
        if (previousHighestBidder) {
          await axios.patch(`https://fundeia.pt/server/user/surpassedNotification/${previousHighestBidder}`, { text: "A sua licitação foi ultrapassada", projectId: project._id, url: `individualproject/${project._id}`, owner: project.user?._id }, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
          });
        }
      }
    } catch (error) {
      errorToast("Ocorreu um erro ao enviar a licitação. Por favor, tente novamente.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (audioRef) {
      setIsAudioReady(true);
    }
  }, [audioRef]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  useEffect(() => {
    const timer = setTimeout(scrollToBottom, 250);
    return () => clearTimeout(timer);
  }, [messages]);

  useEffect(() => {
    if (socket) {
      // Emit the "join room" event to the server when the component mounts
      socket.emit('join room', projectId, user._id);

      socket.on('existing messages', existingMessages => {
        setMessages(existingMessages);
      });

      // Listen for incoming chat messages from the server
      socket.on('chat message', data => {
        setMessages(prevMessages => [...prevMessages, data]);
        setUnreadMessages(unreadMessages => unreadMessages + 1);

      });

      // Clean up the socket connection on unmount
      return () => {
        socket.disconnect();
      };
    }
  }, [socket]);

  const sendMessage = () => {
    if (socket) {
      if (messageInput.trim() !== '') {
        const currentTime = new Date().toISOString();
        socket.emit('chat message', { roomId: projectId, text: messageInput, user: user._id, username: user.name, createdAt: currentTime });
        setMessageInput('');
      }
    }
  };

  const handlePress = (index) => {
    setRatings(index);
  }

  const renderStars = (size) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      stars.push(
        <button
          key={i}
          onClick={() => handlePress(i)}
          className="star-button"
        >
          {i <= ratings ? (
            <FaStar name="star" size={size} color="rgb(255 165 0 / var(--tw-bg-opacity))" />
          ) : (
            <FaRegStar name="star" size={size} color="rgb(255 165 0 / var(--tw-bg-opacity))" />
          )}
        </button>
      );
    }

    return stars;
  };

  useEffect(() => {
    fetchProject();
    fetchUser();
  }, []);


  const fetchProject = () => {
    axios.get(`https://fundeia.pt/server/project/individualproject/${projectId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
      .then(response => {
        setProject(response.data);
        setAudioUrl(response.data.audioUrl);
      })
      .catch(error => {
        errorToast("Houve um erro ao buscar o projeto:", error);
      }).finally(() => {
        setIsLoading(false);
      }
      );
  }

  const fetchUser = () => {
    axios.get(`https://fundeia.pt/server/user/${userId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
      .then(response => {
        setUserDetails(response.data);
        setUserWallet(response.data.wallet);
      })
      .catch(error => {
        errorToast("Houve um erro ao procurar o usuário:", error);
      });
  }

  // Function to play audio for a specific project
  const playAudio = () => {
    const audioElement = audioRef.current;

    setIsPlaying(true);
    audioElement.play();
  };

  const pauseAudio = () => {
    if (isPlaying) {
      audioRef.current.removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
      setIsPlaying(false);
      audioRef.current.pause();
    }
  };

  const handleSliderChange = (e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentAudioTime(newTime);
    audioRef.current.currentTime = newTime;
  };

  const handleAudioTimeUpdate = (e) => {
    const currentTime = e.target.currentTime;
    setCurrentAudioDuration(currentTime);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
        setCurrentAudioTime(0);
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
        audioRef.current.removeEventListener('ended', () => {
          setIsPlaying(false);
          setCurrentAudioTime(0);
        });
      }
    };
  }, [audioRef.current]);

  const markMessagesAsRead = () => {
    setUnreadMessages(0);
  };


  const submitRating = async () => {
    // make sure ratings is not 0
    if (ratings === 0) {
      errorToast("A avaliação não pode ser zero");
      return;
    }

    const projectId = project._id;

    const existingReview = userDetails?.projectsReviews?.find(review => review.projectId === projectId);
    if (existingReview) {
      errorToast(`Já avaliou este projeto anteriormente com ${existingReview.rating} estrelas!`);
      setIsReviewVisible(false);
      return;
    }

    try {
      const response = await axios.patch(`https://fundeia.pt/server/project/rate/${projectId}`, { rating: ratings, user: userId },
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            'Accept': 'application/json', 'Content-Type': 'application/json',
          }
        })
        .then(response => {
          setIsReviewVisible(false);
          successToast("Projeto avaliado com sucesso!");
        });


    } catch (error) {
      errorToast(error);
    }
  };

  const Dropdown = () => {
    return (
      <div className="absolute mt-2 rounded-md shadow-lg bg-white -translate-x-8">
        <ul className="">
          <li
            className="px-4 py-3 hover:bg-gray-200 text-sm"
            onClick={() => { setIsReviewVisible(true) }}
          >
            Avaliar
          </li>
          {/* <li className="px-4 py-3 hover:bg-gray-200 text-sm">Partilhar</li> */}
        </ul>
      </div>
    );
  }

  const handleCheckout = () => {
    if (project.bids && project.bids.length > 0) {

      const confirmation = window.confirm("Tem a certeza de que deseja iniciar o countdown?");

      if (confirmation) {
        try {
          axios.patch(`https://fundeia.pt/server/project/checkout/${projectId}`, {}, {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            },
          })
            .then(response => {
              successToast('Countdown iniciado!');
              fetchProject();
            })
            .catch(error => {
              errorToast('Houve um erro ao iniciar o countdown:', error);
            });
        } catch (error) {
          errorToast('Houve um erro ao iniciar o countdown:', error);
        }
      }
    } else {
      errorToast('Não há licitações para este projeto!');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="containerNavBar bg-gray-100 relative pt-[68px]">
      {projectId === undefined ? <h1 className='pt-32 text-lg'>Entre no projeto pelo feed</h1> :
        <div className="flex w-6/6 sm:w-3/4 lg:w-3/5 mx-auto justify-between space-x-8">

          {/* Left side */}
          <div className="bg-white p-4 my-10 rounded-md shadow-lg w-5/6 lg:w-4/6 mx-auto h-full">
            <div className='mx-5 md:mx-10 mt-2'>
              {userId === project.winner && <h2 className='font-bold text-base md:text-lg mb-4'>És o vencedor deste leilão!</h2>}
              {userId === project.user?._id &&
                <button onClick={navigateToMyProjectPage} className='mb-2 text-white text-sm sm:text-base bg-primary p-2 rounded-lg hover:bg-primary-hover font-bold'>
                  Página de Gestão
                </button>}
              <div className='flex flex-row items-center mb-4'>
                <img src={project.projectPicture} alt="project" className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full" />
                <div className='flex flex-col ml-3 justify-between'>
                  <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>
                  {isMobile && <div className='self-start items-center'>{isReviewVisible ? <div className='flex flex-col items-center'><div className='flex mt-2 mb-3'>
                    {renderStars(20)}
                  </div>
                    <button
                      onClick={submitRating}
                      className="bg-primary text-white px-2 py-1.5 rounded-md hover:bg-primary-hover focus:outline-none text-sm">
                      Avaliar
                    </button> </div> : <Rating rating={project.averageRating} />} </div>}
                  {/* <p className="text-primary text-sm md:text-base text-left"><strong>Valor Pretendido:</strong> {project.desiredValue}€</p> */}
                </div>
                {!isMobile ? (
                  <div className='ml-auto self-start mt-0.5 flex flex-row items-center'>
                    {isReviewVisible ? <div className=''><div className='flex'>
                      {renderStars(18)}
                    </div>
                      <button
                        onClick={submitRating}
                        className="bg-primary text-white px-2 py-2 rounded-md hover:bg-primary-hover focus:outline-none text-sm mt-2">
                        Avaliar
                      </button> </div> : <Rating rating={project.averageRating} />}
                    {project.user?._id === userId ? null : <button className='self-start mt-2 ml-2' onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                      <BsThreeDotsVertical className='h-5 w-5' />
                      {isDropdownVisible && <Dropdown />}
                    </button>}
                  </div>
                ) : (
                  project.user?._id === userId ? null : <button className='self-start mt-2 ml-auto' onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                    <BsThreeDotsVertical className='h-5 w-5' />
                    {isDropdownVisible && <Dropdown />}
                  </button>
                )}
              </div>
              <div className="text-justify text-sm">
                <p>
                  {showMore
                    ? project.description
                    : project.description.length <= (isMobile ? 150 : 360)
                      ? project.description + '.'
                      : `${project.description.substring(0, isMobile ? 150 : 360)}...`
                  }
                </p>
                {(showMore || project.description.length > (isMobile ? 150 : 360)) &&
                  <button
                    className="text-primary text-sm"
                    onClick={toggleShowMore}
                    style={{ display: 'block', margin: '5px auto' }}
                  >
                    {showMore ? 'Mostrar Menos' : 'Mostrar Mais'}
                  </button>
                }
              </div>
            </div>
            {/* Bid Container */}
            <div className="mt-6 bg-gray-50 p-4 rounded-md shadow-lg mx-5 md:mx-10">
              <h3 className="text:base md:text-xl font-bold">Licitações</h3>
              <p className="text-primary text-sm md:text-base text-center mb-2"><strong>Valor Pretendido: {project.desiredValue}€</strong> </p>
              <div>
                {project && project.bids.length > 0 ? (
                  <div>
                    {project.bids
                      .sort((a, b) => b.amount - a.amount)
                      .slice(0, userId === project.user?._id && showAllBids ? undefined : 5)
                      .map((bid, index) => (
                        <div key={index} className="py-2 border-b mb-2 flex flex-row justify-between items-center space-x-2">
                          <p className="font-semibold text-sm md:text-base text-start">{userId === bid.user?._id ? "Eu" : bid.user?.name}</p>
                          <p className="font-bold text-base md:text-lg text-primary">
                            {bid.amount.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' })}
                          </p>
                        </div>
                      ))}
                    {userId === project.user?._id && (
                      <button onClick={() => setShowAllBids(prev => !prev)} className="text-primary">
                        {showAllBids ? "Esconder licitações" : "Mostrar todas as licitações"}
                      </button>
                    )}
                  </div>
                ) : (
                  <p className="text-gray-500">Ainda não houve licitações</p>
                )}
              </div>
              {project.user?._id === userId ? (
                project.status === 'closed' ? (
                  <p className="mt-4 text-red-500 text-sm md:text-base">O leilão está fechado!</p>
                ) : (
                  !project.closingTime ? (
                    <div>
                      <button
                        className="bg-primary text-white px-3 py-2 mt-4 rounded-md hover:bg-primary-hover focus:outline-none transition duration-300 ease-in-out text-sm md:text-base font-bold"
                        onClick={handleCheckout}
                      >
                        Checkout
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col mt-4 font-bold bg-primary text-white p-4 rounded-md">
                      <p className="text-lg">Leilão fecha em:</p>
                      <p className="text-2xl">{timeLeft}</p>
                    </div>
                  )
                )
              )
                : (
                  project.status !== 'closed' ? (
                    <div>
                      <div className="mt-4 flex items-center justify-center">
                        <input
                          type="number"
                          value={bidAmount}
                          onChange={(e) => {
                            if (e.target.value === '' || (/^\d+$/.test(e.target.value) && !e.target.value.includes('.'))) {
                              setBidAmount(e.target.value);
                            }
                          }}
                          className="border px-3 py-2 rounded-l-md mr-0 focus:outline-none w-2/4 sm:w-1/3 md:w-1/4 lg:w-1/4 text-sm md:text-base"
                          placeholder="Valor"
                        />
                        <button
                          onClick={submitBid} // updated to new handler
                          className="bg-primary text-white px-3 py-2 rounded-r-md hover:bg-primary-hover focus:outline-none transition duration-300 ease-in-out text-sm md:text-base font-bold"
                          disabled={isSubmitting} // disable the button when a bid is being submitted
                        >
                          Licitar
                        </button>
                      </div>
                      {project.closingTime && <div className="flex flex-col mt-4 font-bold bg-primary text-white p-4 rounded-md">
                        <p className="text-lg">Leilão fecha em:</p>
                        <p className="text-2xl">{timeLeft}</p>
                      </div>}
                    </div>
                  ) : (
                    <p className="mt-4 text-red-500 text-sm md:text-base">O leilão terminou!</p>
                  )
                )}
            </div>
            {showAudioLeftSide && <div>
              <div className="flex justify-center align-center mt-8">
                <audio ref={audioRef}>
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              {isAudioReady && <div className="bg-gray-100 w-4/5 md:w-2/4 px-6 pt-6 pb-4 mx-auto rounded-md shadow-lg">
                <div className="flex items-center justify-center space-x-2">
                  <button onClick={() => (isPlaying ? pauseAudio() : playAudio())} className='-ml-2'>
                    {isPlaying ? <BsFillPauseFill className='h-6 w-6' /> : <BsFillPlayFill className='h-6 w-6' />}
                  </button>
                  <div className="relative h-4 rounded-md bg-gray-300 mx-auto w-4/5">
                    {audioRef.current && <div className="h-full bg-primary rounded-md" style={{ width: `${(currentAudioDuration / audioRef.current.duration) * 100}%`, minWidth: '2%' }}></div>}
                    {audioRef.current && <input
                      type="range"
                      min="0"
                      max={audioRef.current.duration}
                      step="2"
                      value={currentAudioTime}
                      onChange={handleSliderChange}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />}
                  </div>
                </div>
                {audioRef.current ? <p className="text-black text-sm mt-2">
                  {formatTime(currentAudioDuration)}
                </p> : <p className="text-black text-sm mt-2">0:00</p>}
              </div>}
            </div>}

            {project && (userId === project.winner) && (
              <div className="flex flex-col mt-8 mx-5 md:mx-10">
                <h3 className="text-base md:text-lg font-bold">Dados do criador do projeto</h3>
                <div className="flex flex-col mt-4">
                  <div className="mb-6">
                    <div className="p-4 bg-gray-50 rounded-md shadow-md sm:w-3/4 mx-auto">
                      <div className="flex items-center">
                        <span className="mr-2">
                          <FiUser />
                        </span>
                        <strong className="text-gray-600 text-sm sm:text-base">Nome:</strong>
                      </div>
                      <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{project.user.name}</p>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="p-4 bg-gray-50 rounded-md shadow-md sm:w-3/4 mx-auto">
                      <div className="flex items-center">
                        <span className="mr-2">
                          <HiOutlineMail />
                        </span>
                        <strong className="text-gray-600 text-sm sm:text-base">Email:</strong>
                      </div>
                      <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{project.user.email}</p>
                    </div>
                  </div>

                  <div className="mb-6">
                    <div className="p-4 bg-gray-50 rounded-md shadow-md sm:w-3/4 mx-auto">
                      <div className="flex items-center">
                        <span className="mr-2">
                          <BsHouseDoor />
                        </span>
                        <strong className="text-gray-600 text-sm sm:text-base">Morada:</strong>
                      </div>
                      <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{project.user.address}</p>
                    </div>
                  </div>

                  <div className="">
                    <div className="p-4 bg-gray-50 rounded-md shadow-md sm:w-3/4 mx-auto">
                      <div className="flex items-center">
                        <span className="mr-2">
                          <FiPhone />
                        </span>
                        <strong className="text-gray-600 text-sm sm:text-base">Telemóvel:</strong>
                      </div>
                      <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{project.user.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}


            <h2 className="text-base md:text-lg font-bold mt-8 mb-2">Documentos</h2>
            {project && project.documentUrls.filter(document => document.private).length > 0 && userId === project.winner && (
              <div className="flex flex-col mb-4 mx-5 md:mx-10">
                <h3 className="text-sm md:text-base font-bold text-gray-800">Privados</h3>
                <div className={`grid gap-4 mt-4 mx-auto ${project.documentUrls.filter(document => document.private).length === 1 ? 'place-items-center grid-cols-1' : project.documentUrls.filter(document => document.private).length === 2 ? 'place-items-center grid-cols-2' : 'grid-cols-2 sm:grid-cols-3'}`}>
                  {project.documentUrls.filter(document => document.private).map((document, index) => {
                    const isPDF = document.path?.endsWith('.pdf');
                    return (
                      <a
                        key={index}
                        href={document.path}
                        target="_blank"
                        rel="noreferrer"
                        className="p-1 border rounded-md flex flex-col items-center justify-center hover:bg-gray-100 transition duration-300 h-24 sm:h-36 w-full"
                      >
                        {isPDF ? (
                          <div className="flex items-center justify-center">
                            <AiFillFilePdf size={50} />
                          </div>
                        ) : (
                          <img
                            src={document.path}
                            alt={`Image Document ${index + 1}`}
                            className="object-contain w-full h-full"
                          />
                        )}
                        {isPDF && <p className='text-sm md:text-base mt-2 px-[53px]'>PDF</p>}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}

            {/* documentos e fotos */}
            {project && project.documentUrls?.filter(document => !document.private).length > 0 ? (
              <div className="flex flex-col mb-4 mx-5 md:mx-10">
                <h3 className="text-sm md:text-base font-bold text-gray-800">Públicos</h3>
                <div className={`grid gap-4 mt-4 mx-auto ${project.documentUrls.filter(document => !document.private).length === 1 ? 'place-items-center grid-cols-1' : project.documentUrls.filter(document => !document.private).length === 2 ? 'place-items-center grid-cols-2' : 'grid-cols-2 sm:grid-cols-3'}`}>
                  {project.documentUrls.filter(document => !document.private).map((document, index) => {
                    const isPDF = document.path?.endsWith('.pdf');
                    return (
                      <a
                        key={index}
                        href={document.path}
                        target="_blank"
                        rel="noreferrer"
                        className="p-1 border rounded-md flex flex-col items-center justify-center hover:bg-gray-100 transition duration-300 h-24 sm:h-36 w-full"
                      >
                        {isPDF ? (
                          <div className="flex items-center justify-center">
                            <AiFillFilePdf size={50} />
                          </div>
                        ) : (
                          <img
                            src={document.path}
                            alt={`Image Document ${index + 1}`}
                            className="object-contain w-full h-full"
                          />
                        )}
                        {isPDF && <p className='text-sm md:text-base mt-2 px-[53px]'>PDF</p>}
                      </a>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="text-gray-700 my-4">Não há documentos</p>
            )}


          </div>

          {/* Right side */}
          <div className="w-[30%] mt-10 hidden lg:block">
            <div className="bg-white rounded-t-md shadow-lg h-96 overflow-y-scroll overflow-x-hidden" ref={messageContainerRef}>
              {project.user?._id === user._id ? null : <div className="space-y-3 px-4 pt-4">
                {messages.length > 0 ? messages.map((message, index) => (
                  <div
                    key={index}
                    className={`p-2 max-w-[95%] ${message.user === user._id
                      ? 'bg-primary text-white rounded-md mr-auto'
                      : 'bg-gray-200 text-gray-800 rounded-md ml-auto'
                      }`}
                  >
                    <p
                      className={`text-wrap text-sm pl-2 ${message.user === user._id ? 'text-left' : 'text-left'}`}
                    >
                      <strong>
                        {message.user === user._id ? 'Eu' : message.username}:
                      </strong>{' '}
                      {message.text}
                    </p>
                    <p className={`text-xs text-right ${message.user === user._id ? 'text-gray-100' : 'text-gray-700'}`}>
                      {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </p>
                  </div>
                )) : <p className='mt-40 text-sm'>Ainda não há mensagens, seja o primeiro a enviar.</p>}
              </div>}
              <div ref={messagesEndRef} />
              {showArrow && (
                <button className="sticky bottom-2 m-2 justify-end cursor-pointer w-1/8 float-right rounded-full" onClick={scrollToBottom}>
                  <BiSolidChevronDownCircle size={28} className="text-primary bg-white rounded-full" />
                </button>
              )}
            </div>
            {project.user?._id === user._id ?
              <div className="flex sticky bottom-0 px-4 py-3 bg-white rounded-b-md">
                <div className="flex w-full">
                  <p className='text-sm'>O dono do projeto não pode participar no chat.</p>
                </div>
              </div>
              : <div className="flex sticky bottom-0 px-4 py-3 bg-white rounded-b-md shadow-lg">
                <div className="flex w-full">
                  <input
                    type="text"
                    className="flex-grow border rounded-l-md px-2 py-1 focus:outline-none sm:w-4/5 md:w-3/4 lg:w-2/3"
                    value={messageInput}
                    placeholder="Escreva uma mensagem..."
                    onChange={(e) => setMessageInput(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        sendMessage();
                      }
                    }}
                  />
                  <button
                    className="bg-primary px-4 py-1 rounded-r-md hover:bg-primary-hover focus:outline-none w-11"
                    onClick={sendMessage}
                  >
                    <IoMdSend />
                  </button>
                </div>
              </div>}
            <div>
              <div className="flex justify-center align-center mt-8">
                <audio ref={audioRef}>
                  <source src={audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              {isAudioReady && <div className="bg-white px-6 pt-6 pb-4 mx-auto rounded-md shadow-lg">
                <div className="flex items-center justify-center space-x-2">
                  <button onClick={() => (isPlaying ? pauseAudio() : playAudio())} className='-ml-2'>
                    {isPlaying ? <BsFillPauseFill className='h-6 w-6' /> : <BsFillPlayFill className='h-6 w-6' />}
                  </button>
                  <div className="relative h-4 rounded-md bg-gray-100 mx-auto w-4/5">
                    {audioRef.current && <div className="h-full bg-primary rounded-md" style={{ width: `${(currentAudioDuration / audioRef.current.duration) * 100}%`, minWidth: '2%' }}></div>}
                    {audioRef.current && <input
                      type="range"
                      min="0"
                      max={audioRef.current.duration}
                      step="2"
                      value={currentAudioTime}
                      onChange={handleSliderChange}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />}
                  </div>
                </div>
                {audioRef.current ? <p className="text-black text-sm mt-2">
                  {formatTime(currentAudioDuration)}
                </p> : <p className="text-black text-sm mt-2">0:00</p>}
              </div>}
            </div>

          </div>

          <div className='fixed bottom-5 right-5 lg:hidden'>
            <button className="bg-primary p-4 rounded-xl text-white hover:bg-primary-hover focus:outline-none" onClick={() => {
              setChatModalIsOpen(true);
              markMessagesAsRead();

              setTimeout(() => {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
              }, 0);
            }}>
              <BsFillChatSquareDotsFill />
              {unreadMessages > 0 && !chatModalIsOpen && <div className="bg-red-500 w-4 h-4 rounded-full absolute -top-0.5 right-0 text-white flex items-center justify-center text-xs">{unreadMessages}</div>}

            </button>
            {chatModalIsOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex-col">
                <div className="bg-white rounded-lg shadow-lg w-5/6 sm:w-[62.5%] pt-3">
                  <div className="overflow-y-scroll rounded-md space-y-3 px-4" style={{ maxHeight: '60vh' }} ref={chatContainerRef}>
                    {messages.length > 0 ? messages.map((message, index) => (
                      <div
                        key={index}
                        className={`p-2 max-w-[95%] ${message.user === user._id
                          ? 'bg-primary text-white rounded-md mr-auto'
                          : 'bg-gray-200 text-gray-800 rounded-md ml-auto'
                          }`}
                      >
                        <p className={`text-wrap text-sm pl-2 ${message.user === user._id ? 'text-left' : 'text-left'}`} >
                          <strong>
                            {message.user === user._id ? 'Eu' : message.username}:
                          </strong>{' '}
                          {message.text}
                        </p>
                        <p className={`text-xs text-right ${message.user === user._id ? 'text-gray-100' : 'text-gray-700'}`}>
                          {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </p>
                      </div>
                    )) : <p className='mt-2 text-sm'>Ainda não há mensagens, seja o primeiro a enviar.</p>}
                    <div ref={messagesEndRef} />
                    {showArrow && (
                      <button className="sticky bottom-2 -mr-2 justify-end cursor-pointer w-1/8 float-right rounded-full" onClick={scrollToBottom}>
                        <BiSolidChevronDownCircle size={32} className="text-primary bg-white rounded-full" />
                      </button>
                    )}
                  </div>
                  {project?.user._id === user._id ?
                    <div className="px-4 py-3 mx-auto">
                      <p className='text-sm'>O dono do projeto não pode participar no chat.</p>
                    </div>
                    : <div className="flex px-4 py-3">
                      <input
                        type="text"
                        className="flex-grow border rounded-l-md px-2 py-1 focus:outline-none w-4/5"
                        value={messageInput}
                        placeholder="Escreva uma mensagem..."
                        onChange={(e) => setMessageInput(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            sendMessage();
                          }
                        }}
                      />
                      <button
                        className="bg-primary px-4 py-1 rounded-r-md hover:bg-primary-hover focus:outline-none"
                        onClick={sendMessage}
                      >
                        <IoMdSend />
                      </button>
                    </div>}
                </div>
                {chatModalIsOpen && (
                  <button
                    className="bg-red-500 text-white px-4 py-2 mt-6 rounded-md hover:bg-red-600 focus:outline-none"
                    onClick={() => closeChat()}
                  >
                    Fechar Chat
                  </button>
                )}
              </div>
            )}
          </div>
        </div>}
    </div>
  );
}

export default IndividualProjectPage;