import "./App.css";
import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { AuthContext } from "./context/auth.context";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SignupPage from "./pages/SignupPage/SignupPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import FeedPage from "./pages/FeedPage/FeedPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import IndividualProjectPage from "./pages/IndividualProjectPage/IndividualProjectPage";
import MyProjectPage from "./pages/ProjectsPage/MyProjectPage";
import MyBidsPage from "./pages/MyBidsPage/MyBidsPage";
import InfoPage from "./pages/InfoPage/InfoPage";

import Navbar from "./components/Navbar/Navbar";
import IsPrivate from "./components/IsPrivate/IsPrivate";
import IsAnon from "./components/IsAnon/IsAnon";


function App() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route path="/" element={isLoggedIn ? <FeedPage /> : <LoginPage />} />
        <Route path="/home" element={<HomePage />} />

        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />

        <Route
          path="/feed"
          element={
            <IsPrivate>
              <FeedPage />
            </IsPrivate>
          }
        />

        <Route
          path="/projects"
          element={
            <IsPrivate>
              <ProjectsPage />
            </IsPrivate>
          }
        />

        <Route
          path="/mybids"
          element={
            <IsPrivate>
              <MyBidsPage />
            </IsPrivate>
          }
        />

        <Route
          path="/individualproject/:id"
          element={
            <IsPrivate>
              <IndividualProjectPage />
            </IsPrivate>
          }
        />

        <Route
          path="/myproject/:id"
          element={
            <IsPrivate>
              <MyProjectPage />
            </IsPrivate>
          }
        />

        <Route
          path="/signup"
          element={
            <IsAnon>
              <SignupPage />
            </IsAnon>
          }
        />
        <Route
          path="/login"
          element={
            <IsAnon>
              <LoginPage />
            </IsAnon>
          }
        />
        <Route path="/info" element={<InfoPage />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
