import "./SignupPage.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import { FiUser, FiPhone } from 'react-icons/fi';
import { MdLockOutline } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { BsHouseDoor } from 'react-icons/bs';
import axios from "axios";
import { successToast } from "../../components/Toast/Toast";


function SignupPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isPasswordVisible1, setIsPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [isOTPVerification, setIsOTPVerification] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerification, setOtpVerification] = useState("");
  const [smsOtp, setSmsOtp] = useState("");

  const navigate = useNavigate();

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleName = (e) => setName(e.target.value);
  const handleRepeatPassword = (e) => setRepeatPassword(e.target.value);
  const handleAddress = (e) => setAddress(e.target.value);
  const handlePhone = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9) { // Restrict to 9 digits
      setPhone(inputValue);
    }
  };
  const handleOTP = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) { // Restrict to 9 digits
      setOtp(inputValue);
    }
  };

  const handleSmsOtp = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) {
      setSmsOtp(inputValue);
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    if (otp === '' || otp === undefined || otp === null || otp.length < 6) {
      setErrorMessage("Preencha o OTP do email!");
      return;
    }

    if (otp.toString() !== otpVerification.toString()) {
      setErrorMessage("O OTP do email não é válido!");
      return;
    }

    if (smsOtp === '' || smsOtp === undefined || smsOtp === null || smsOtp.length < 6) {
      setErrorMessage("Preencha o OTP do telemóvel!");
      return;
    }

    const otpSMS = await axios.post("https://fundeia.pt/server/auth/verify-otp", { otp: smsOtp, phone });
    const otpSMSResponse = otpSMS.data.status;


    if (otpSMSResponse !== "approved") {
      setErrorMessage("O OTP do telemóvel não é válido!");
      return;
    }

    if (!email || !password || !name || !address || !phone) {
      setErrorMessage("Todos os campos são obrigatórios!");
      return;
    }

    if (password !== repeatPassword) {
      setErrorMessage("As palavras-passe não coincidem");
      return;
    }

    // Create an object representing the request body
    const requestBody = { email, password, name, address, phone };

    authService
      .signup(requestBody)
      .then((response) => {
        // If the POST request is successful redirect to the login page
        navigate("/login");
        successToast("Conta criada com sucesso!");
      })
      .catch((error) => {
        // If the request resolves with an error, set the error message in the state
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      });
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();

    if (!email || !password || !name || !address || !phone) {
      setErrorMessage("Todos os campos são obrigatórios!");
      return;
    }

    if (password !== repeatPassword) {
      setErrorMessage("As palavras-passe não coincidem");
      return;
    }

    // Create an object representing the request body
    const requestBody = { email, password, name, address, phone };

    authService
      .sendOTP(requestBody)
      .then((response) => {
        if (response.status === 200) {
          setOtpVerification(response.data.otp);
          setIsOTPVerification(true);
          setErrorMessage(undefined)
        }
      })
      .catch((error) => {
        // If the request resolves with an error, set the error message in the state
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      });
  };

  return (
    <div className="flex items-center justify-center h-screen LoginPage bg-primary">
      <div className="flex w-full max-w-5xl py-10 mx-4 bg-white border divide-gray-200 rounded-lg min-h-2/3 sm:divide-x-2">

        {/* Left Side Content */}
        <div className="hidden w-1/2 px-8 sm:block">
          <img src="/fundeia.png" alt="Logo" className="w-24 h-24 mx-auto mb-6" />
          <p className="text-sm text-center sm:px-14">UMA COMUNIDADE SEGURA</p>
          <p className="text-sm">+</p>
          <p className="text-sm text-center sm:px-14">UM MERCADO EXCLUSIVO</p>
          <p className="text-sm">+</p>
          <p className="text-sm text-center sm:px-14">UMA NOVA ALTERNATIVA DE FINANCIAMENTO!</p>
          <br />
          <p className="text-sm text-center sm:px-12"><strong>O NEGÓCIO DA SUA VIDA PODE ESTAR AQUI, NA FUNDEIA.</strong></p>
          <img src="/loginSignUpImage.jpg" alt="Login and Sign Up" className="w-full" />
        </div>

        {/* Right Side Signup */}
        <div className="p-8 mx-auto bg-white sm:w-1/2 w-1/1">
          <div className="flex justify-center mx-auto mb-8 -mt-8 sm:w-3/4 md:w-4/6 sm:mb-12">
            <Link to="/login" className={`py-2 sm:w-2/4 md:w-2/6 w-1/3 text-white rounded bg-gray-400 text-sm font-bold`}>
              Login
            </Link>
            <Link to="/signup" className={`py-2 sm:w-2/4 w-1/3 md:w-2/6 text-white rounded bg-primary text-sm -ml-1 z-10 font-bold`}>
              Sign Up
            </Link>
          </div>

          <div className="flex mx-auto mb-10 sm:w-3/4 md:w-4/6">
            <h1 className="relative font-bold text-md">
              Sign Up
              <div className="absolute -bottom-2 left-0 inset-x-8 h-0.5 bg-primary"></div>
            </h1>
          </div>

          {isOTPVerification ?
            <>
              <form onSubmit={handleSignupSubmit} className="space-y-4">
                <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                  <HiOutlineMail className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                  <input
                    type="number"
                    placeholder="OTP Email"
                    value={otp}
                    onChange={handleOTP}
                    className="w-full px-8 py-2 pr-2 text-sm border border-gray-400 rounded-l"
                  />
                </div>
                {/*                 <div className="pt-3">
                  <button type="submit" className="w-3/4 p-2 text-white rounded-md bg-primary hover:bg-primary-hover sm:w-2/4">Sign Up</button>
          </div> */}
                <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                  <FiPhone className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                  <input
                    type="number"
                    placeholder="OTP Telemóvel"
                    value={smsOtp}
                    onChange={handleSmsOtp}
                    className="w-full px-8 py-2 pr-2 text-sm border border-gray-400 rounded-l"
                  />
                </div>
                <div className="">
                  <button type="submit" className="w-3/4 p-2 text-white rounded-md bg-primary hover:bg-primary-hover sm:w-2/4">Sign Up</button>
                </div>
              </form>
            </>
            :
            <form onSubmit={handleOTPSubmit} className="space-y-4">
              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <FiUser className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleName}
                  autoComplete="name"
                  className="w-full py-2 pl-8 pr-2 text-sm border border-gray-400 rounded-l"
                  placeholder="Nome"
                  maxLength={40}
                />
              </div>

              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <HiOutlineMail className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmail}
                  autoComplete="email"
                  className="w-full py-2 pl-8 pr-2 text-sm border border-gray-400 rounded-l"
                  placeholder="Email"
                  maxLength={50}
                />
              </div>

              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <BsHouseDoor className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type="text"
                  name="address"
                  value={address}
                  onChange={handleAddress}
                  autoComplete="address"
                  className="w-full py-2 pl-8 pr-2 text-sm border border-gray-400 rounded-l"
                  placeholder="Morada"
                  maxLength={70}
                />
              </div>

              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <FiPhone className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type="number"
                  name="phone"
                  value={phone}
                  onChange={handlePhone}
                  autoComplete="phone"
                  className="w-full py-2 pl-8 pr-2 text-sm border border-gray-400 rounded-l"
                  placeholder="Telemóvel"
                />
              </div>

              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <MdLockOutline className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type={isPasswordVisible1 ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={handlePassword}
                  autoComplete="new-password"
                  className="w-full px-8 py-2 pr-10 text-sm border border-gray-400 rounded-l"
                  placeholder="Palavra-Passe"
                />
                <span
                  onClick={() => setIsPasswordVisible1(!isPasswordVisible1)}
                  className="absolute z-10 text-xl text-black transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                >
                  {isPasswordVisible1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>

              <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                <MdLockOutline className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                <input
                  type={isPasswordVisible2 ? "text" : "password"}
                  name="repeatPassword"
                  value={repeatPassword}
                  onChange={handleRepeatPassword}
                  autoComplete="new-password"
                  className="w-full px-8 py-2 pr-10 text-sm border border-gray-400 rounded-l"
                  placeholder="Repetir Palavra-Passe"
                />
                <span
                  onClick={() => setIsPasswordVisible2(!isPasswordVisible2)}
                  className="absolute z-10 text-xl text-black transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                >
                  {isPasswordVisible2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </span>
              </div>

              <div className="pt-3">
                <button type="submit" className="w-3/4 p-2 font-bold text-white rounded-md bg-primary hover:bg-primary-hover sm:w-2/4">Enviar Código</button>
                <p className="mx-auto mt-2 text-xs sm:w-3/4 md:w-4/6">Receberá um código de verificação por email e por SMS</p>
              </div>
            </form>}

          {errorMessage && <p className="mt-4 text-red-500 error-message">{errorMessage}</p>}

          <div className="flex flex-row justify-between mx-auto mt-10 space-x-4 sm:w-6/7 md:w-4/6">
            <a href="https://drive.google.com/file/d/1RkrFGr8q4KYLM77doAWZGyy58Is3mwVZ/view?usp=drivesdk" className="flex items-center justify-center text-sm text-black">
              <span>Confidencialidade</span>
            </a>

            <a href="https://drive.google.com/file/d/1KVgmI43bEIidsKnhJ4OqKop-1-0Gzgwq/view?usp=drivesdk" className="flex items-center justify-center text-sm text-black">
              <span>Manual de Utilização</span>
            </a>
          </div>

        </div>
      </div>
    </div >
  );
}

export default SignupPage;
