import "./MyBidsPage.css";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import axios from 'axios';
import { successToast, errorToast } from '../../components/Toast/Toast';
import { BsPlus } from 'react-icons/bs';
import Rating from "../../components/Rating/Rating";
import { BiSolidMedal } from 'react-icons/bi';
import { FaMedal } from 'react-icons/fa';
import { AiOutlineArrowRight } from 'react-icons/ai';
import Countdown from '../../components/Countdown/Countdown';

function MyBidsPage() {
    const [projects, setProjects] = useState([]);
    const { user } = useContext(AuthContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRefs = useRef({});
    const [showMore, setShowMore] = useState({});
    const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
    const [currentAudioProjectId, setCurrentAudioProjectId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();

    const navigate = useNavigate();

    const navigateToBidPage = (project) => {
        const projectId = project._id;
        navigate(`/individualproject/${projectId}`, { state: { projectId } });
    };

    const resetSearch = () => {
        setSearchQuery('');
        setSearchResults([]);
        navigate('/mybids');
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('q');
        if (query !== null) {
            setSearchQuery(query);
        }
    }, [location.search]);

    useEffect(() => {
        if (searchQuery) {
            initiateSearch();
        }
    }, [searchQuery]);

    const initiateSearch = async () => {
        try {
            const response = await axios.get('https://fundeia.pt/server/project/searchMyBids', {
                params: { q: searchQuery, userId: user._id },
                headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error("Houve um erro ao buscar os projetos:", error);
        }
    };

    const fetchProjects = () => {
        axios.get(`https://fundeia.pt/server/project/mybids/${user._id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error("Houve um erro ao buscar os projetos:", error);
            });
    }

    useEffect(() => {
        fetchProjects();
    }, []);

    // Function to play audio for a specific project
    const playAudio = (projectId) => {
        const audioElement = audioRefs.current[projectId];

        // Pause any currently playing audio
        for (const id in audioRefs.current) {
            if (audioRefs.current[id] !== audioElement) {
                audioRefs.current[id].pause();
            }
        }

        // Play the selected audio
        setCurrentAudioProjectId(projectId);
        audioElement.currentTime = 0;
        setIsPlaying(true);
        audioElement.play();
    };

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    const toggleShowMore = (projectId) => {
        setShowMore(prevState => ({ ...prevState, [projectId]: !prevState[projectId] }));
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const pauseAudio = (projectId) => {
        if (isPlaying) {
            audioRefs.current[projectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            setCurrentAudioDuration(0);
            setCurrentAudioProjectId(null);
            setIsPlaying(false);
            audioRefs.current[projectId].pause();
        }
    };


    const handleAudioTimeUpdate = (e, projectId) => {
        if (currentAudioProjectId === projectId) {
            const currentTime = e.target.currentTime;
            setCurrentAudioDuration(currentTime);
        }
    };

    useEffect(() => {
        if (audioRefs.current[currentAudioProjectId]) {
            audioRefs.current[currentAudioProjectId].addEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
        }

        return () => {
            if (audioRefs.current[currentAudioProjectId]) {
                audioRefs.current[currentAudioProjectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            }
        };
    }, [currentAudioProjectId]);

    return (
        <div className="containerNavBar bg-gray-100 pt-[68px]">
            <div className="containerNavBar flex w-5/6 sm:w-3/5 mx-auto space-x-4">

                <div className="mx-auto w-full">
                    <h1 className="text-2xl mt-4 mb-6 font-semibold">Projetos Licitados</h1>

                    <div className="">
                        {searchQuery && (
                            <>
                                <button className="mb-4 bg-primary rounded-md hover:bg-primary-hover p-2 text-white text-base font-bold" onClick={resetSearch}>Limpar Pesquisa</button>
                                <p className="mb-4 text-base sm:text-xl font-bold">Estes são os resultados da pesquisa:</p>
                            </>
                        )}
                        {projects
                            .filter(project =>
                                project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                project.description.toLowerCase().includes(searchQuery.toLowerCase())
                            ).length === 0 ? (
                            <>
                                <p className="pb-10 font-semibold text-sm sm:text-base">Não foram encontrados resultados para a sua pesquisa</p>
                            </>
                        ) : (
                            projects
                                .filter(project =>
                                    project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                    project.description.toLowerCase().includes(searchQuery.toLowerCase())
                                )
                                .sort((a, b) => {
                                    const userBidsA = a.bids.filter(bid => bid.userId === user._id);
                                    const userBidsB = b.bids.filter(bid => bid.userId === user._id);

                                    const lastUserBidA = userBidsA[userBidsA.length - 1];
                                    const lastUserBidB = userBidsB[userBidsB.length - 1];

                                    if (lastUserBidA && lastUserBidB) {
                                        return new Date(lastUserBidB.timestamp) - new Date(lastUserBidA.timestamp);
                                    } else if (lastUserBidA) {
                                        return -1; // Project A comes first if user has bid on A but not on B
                                    } else if (lastUserBidB) {
                                        return 1; // Project B comes first if user has bid on B but not on A
                                    } else {
                                        return 0; // Neither project has a bid from the user, so leave them as is
                                    }
                                })
                                .map(project => (
                                    <div
                                        key={project._id}
                                        className={`my-4 py-5 md:py-8 rounded-md shadow-lg bg-white`}>
                                        <div className="relative mx-5 md:mx-8">
                                            <div className="top-right absolute -right-2 -top-2 md:-right-4 md:-top-4">
                                                {project.winner === user._id && (
                                                    <FaMedal size={24} color="#D6AF36" />
                                                )}
                                            </div>
                                            <div className='flex flex-row items-center mb-4'>
                                                <img src={project.projectPicture} alt="project" className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full" />
                                                <div className='flex flex-col ml-3 justify-between'>
                                                    <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>
                                                    {isMobile && project.averageRating > 0 &&
                                                        <div className='self-start items-center'>
                                                            <Rating rating={project.averageRating} />
                                                        </div>}
                                                </div>
                                                {!isMobile && project.averageRating > 0 &&
                                                    <div className={`ml-auto self-start'mt-0.5'} flex flex-row items-center`}>
                                                        <Rating rating={project.averageRating} />
                                                    </div>}
                                            </div>
                                            <div className="text-justify text-sm">
                                                <p>
                                                    {showMore[project._id]
                                                        ? project.description
                                                        : project.description.length <= (isMobile ? 120 : 350)
                                                            ? project.description
                                                            : `${project.description.substring(0, isMobile ? 120 : 350)}...`
                                                    }
                                                </p>
                                                {(showMore[project._id] || project.description.length > (isMobile ? 120 : 350)) &&
                                                    <button
                                                        className="text-primary text-sm"
                                                        onClick={() => toggleShowMore(project._id)}
                                                        style={{ display: 'block', margin: '5px auto' }}
                                                    >
                                                        {showMore[project._id] ? 'Mostrar Menos' : 'Mostrar Mais'}
                                                    </button>
                                                }
                                            </div>

                                            <div>
                                                {project.bids && project.bids.length > 0 && project.status === "open" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão a decorrer</p>
                                                    : project.status === "closed" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão fechado</p> : null}
                                                {project.closingTime && project.status != 'closed' &&  <Countdown closingTime={project.closingTime} status={project.status} id={project._id} />}
                                                <p className="text-black my-2 text-sm md:text-base"><strong>Valor Pretendido:</strong> {project.desiredValue}€</p>
                                            </div>

                                            <div className="flex justify-center align-center mt-4">
                                                {currentAudioProjectId === project._id && isPlaying ? (
                                                    <button className="pause-button font-bold" onClick={() => pauseAudio(project._id)}>
                                                        Pausar áudio
                                                    </button>
                                                ) : (
                                                    <button className="play-button font-bold" onClick={() => playAudio(project._id)}>
                                                        Reproduzir áudio
                                                    </button>
                                                )}
                                                <audio ref={el => (audioRefs.current[project._id] = el)}>
                                                    <source src={project.audioUrl} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                            {currentAudioProjectId === project._id && (
                                                <div className="mt-4 bg-gray-100 w-4/5 md:w-2/5 px-6 pt-6 pb-4 mx-auto rounded-md">
                                                    <div className="relative h-4 rounded-md bg-gray-300 mx-auto">
                                                        <div className="h-full bg-primary rounded-md " style={{ width: `${(currentAudioDuration / audioRefs.current[project._id].duration) * 100}%`, minWidth: '2%' }}></div>
                                                    </div>
                                                    <p className="text-black text-sm mt-2">
                                                        {formatTime(currentAudioDuration)} / {formatTime(audioRefs.current[project._id].duration)}
                                                    </p>
                                                </div>
                                            )}
                                            <div className="flex justify-end"><button
                                                className="px-3 py-3 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                                onClick={() => navigateToBidPage(project)}
                                            >
                                                <AiOutlineArrowRight size={18} />
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyBidsPage;
