import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function Countdown({ closingTime, status, id }) {
  const [timeLeft, setTimeLeft] = useState('');

  const closeProject = () => {
    axios.patch(`https://fundeia.pt/server/project/closeproject/${id}`, {},
      {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
      })
      .then((res) => {
      })
      .catch(error => {
        console.error('Error closing project:', error);
      });
  };

  const intervalIdRef = useRef(null);

  const updateCountdown = () => {
    const now = new Date();
    const endTime = new Date(closingTime);
    const distance = endTime - now;

    if (distance <= 0) {
      clearInterval(intervalIdRef.current);
      setTimeLeft('Encerrado!');
      closeProject();
      return;
    }

    const hours = Math.floor(distance / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setTimeLeft(`${hours}H ${minutes}M ${seconds}S`);
  };

  useEffect(() => {
    if (closingTime && status !== 'closed') {
      updateCountdown();  // update immediately
      intervalIdRef.current = setInterval(updateCountdown, 1000);
      return () => clearInterval(intervalIdRef.current);  // cleanup on unmount
    }
  }, [closingTime, status]);

  return (
    <div className="flex flex-col mt-4 font-bold bg-primary text-white p-2 rounded-md mx-auto">
      <p className="text-lg">Leilão fecha em:</p>
      <p className="text-lg">{timeLeft}</p>
    </div>
  );
}

export default Countdown;