import "./MyProjectPage.css";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from "../../context/auth.context";
import { IoMdSend } from 'react-icons/io';
import { BsFillChatSquareDotsFill, BsFillPauseFill, BsFillPlayFill, BsThreeDotsVertical } from 'react-icons/bs';
import { FaStar, FaRegStar } from 'react-icons/fa';
import Loading from "../../components/Loading/Loading";
import Rating from "../../components/Rating/Rating";
import { successToast, errorToast } from '../../components/Toast/Toast';
import { AiFillFilePdf, AiFillEdit, AiFillLock, AiOutlineUnlock } from 'react-icons/ai';
import { FiUser, FiPhone } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { BsTrashFill, BsFillCheckSquareFill, BsPlus } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';


function MyProjectPage() {
    const location = useLocation();
    const projectId = location.state?.projectId;
    const [project, setProject] = useState({});
    const { user } = useContext(AuthContext);
    const [ratings, setRatings] = useState(3);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
    const [currentAudioTime, setCurrentAudioTime] = useState(0);
    const audioRef = useRef(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAudioReady, setIsAudioReady] = useState(false);
    const userId = user._id;
    const [userWallet, setUserWallet] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showAudioLeftSide, setShowAudioLeftSide] = useState(window.innerWidth < 1024);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isReviewVisible, setIsReviewVisible] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [isTitleEditable, setTitleEditable] = useState(false);
    const [editedTitle, setEditedTitle] = useState(project?.title || '');
    const [isDescriptionEditable, setDescriptionEditable] = useState(false);
    const [editedDescription, setEditedDescription] = useState(project?.description || '');
    const [isDesiredValueEditable, setDesiredValueEditable] = useState(false);
    const [editedDesiredValue, setEditedDesiredValue] = useState(project?.desiredValue || '');
    const [editedAudio, setEditedAudio] = useState(null);
    const [isUpdatingAudio, setIsUpdatingAudio] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [isAddingDocuments, setIsAddingDocuments] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (isModalOpen && !event.target.closest('.modal')) {
                closeModal();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isModalOpen]);

    const openModal = (document) => {
        setSelectedDocument(document);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedDocument(null);
    };

    const handleDocumentChange = (e) => {
        const files = Array.from(e.target.files);
        const existingFileNames = selectedDocuments.map(doc => doc.name);

        const newFiles = files.filter(file => !existingFileNames.includes(file.name));

        setSelectedDocuments(prevDocuments => [...prevDocuments, ...newFiles]);
    };

    const handleEditAudioChange = (e) => {
        const file = e.target.files[0];
        setEditedAudio(file);
    };

    const navigate = useNavigate();

    const navigateToAuctionPage = () => {
        navigate(`/individualproject/${projectId}`, { state: { projectId } });
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setShowAudioLeftSide(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (audioRef) {
            setIsAudioReady(true);
        }
    }, [audioRef]);

    const handlePress = (index) => {
        setRatings(index);
    }

    const renderStars = (size) => {
        const stars = [];

        for (let i = 1; i <= 5; i++) {
            stars.push(
                <button
                    key={i}
                    onClick={() => handlePress(i)}
                    className="star-button"
                >
                    {i <= ratings ? (
                        <FaStar name="star" size={size} color="rgb(255 165 0 / var(--tw-bg-opacity))" />
                    ) : (
                        <FaRegStar name="star" size={size} color="rgb(255 165 0 / var(--tw-bg-opacity))" />
                    )}
                </button>
            );
        }

        return stars;
    };

    useEffect(() => {
        fetchProject();
        fetchUser();
    }, []);


    const fetchProject = () => {
        axios.get(`https://fundeia.pt/server/project/individualproject/${projectId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                setProject(response.data);
                setAudioUrl(response.data.audioUrl);
            })
            .catch(error => {
                errorToast("Houve um erro ao buscar o projeto:", error);
            }).finally(() => {
                setIsLoading(false);
            }
            );
    }

    const fetchUser = () => {
        axios.get(`https://fundeia.pt/server/user/${userId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                setUserDetails(response.data);
                setUserWallet(response.data.wallet);
            })
            .catch(error => {
                errorToast("Houve um erro ao procurar o usuário:", error);
            });
    }

    // Function to play audio for a specific project
    const playAudio = () => {
        const audioElement = audioRef.current;

        setIsPlaying(true);
        audioElement.play();
    };

    const pauseAudio = () => {
        if (isPlaying) {
            audioRef.current.removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
            setIsPlaying(false);
            audioRef.current.pause();
        }
    };

    const handleSliderChange = (e) => {
        const newTime = parseFloat(e.target.value);
        setCurrentAudioTime(newTime);
        audioRef.current.currentTime = newTime;
    };

    const handleAudioTimeUpdate = (e) => {
        const currentTime = e.target.currentTime;
        setCurrentAudioDuration(currentTime);
    };

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
            audioRef.current.addEventListener('ended', () => {
                setIsPlaying(false);
                setCurrentAudioTime(0);
            });
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e));
                audioRef.current.removeEventListener('ended', () => {
                    setIsPlaying(false);
                    setCurrentAudioTime(0);
                });
            }
        };
    }, [audioRef.current]);

    const Dropdown = () => {
        return (
            <div className="absolute mt-2 rounded-md shadow-lg bg-white -translate-x-8">
                <ul className="">
                    <li
                        className="px-4 py-3 hover:bg-gray-200 text-sm"
                        onClick={() => { setIsReviewVisible(true) }}
                    >
                        Avaliar
                    </li>
                    <li className="px-4 py-3 hover:bg-gray-200 text-sm">Partilhar</li>
                </ul>
            </div>
        );
    }

    const deleteProject = () => {
        if (window.confirm("Tem a certeza que quer eliminar este projeto?")) {
            axios.delete(`https://fundeia.pt/server/project/deleteproject/${projectId}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
                .then(response => {
                    if (response.status === 200) {
                        successToast("Projeto eliminado com sucesso!");
                        navigate('/projects');
                    }
                })
                .catch(error => {
                    errorToast("Houve um erro ao eliminar o projeto:", error);
                });
        }
    }

    const cancelEditable = () => {
        setTitleEditable(false);
        setEditedTitle('');
    }

    const cancelDescriptionEditable = () => {
        setDescriptionEditable(false);
        setEditedDescription('');
    }

    const cancelDesiredValueEditable = () => {
        setDesiredValueEditable(false);
        setEditedDesiredValue('');
    }

    const updateProjectTitle = (title) => {
        if (title === '') {
            errorToast("O título não pode estar vazio!");
            return;
        }
        axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, { title }, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                if (response.status === 200) {
                    successToast("Título atualizado com sucesso!");
                    setTitleEditable(false);
                    setEditedTitle('');
                    fetchProject();
                }
            })
            .catch(error => {
                errorToast("Houve um erro ao atualizar o título do projeto:", error);
            });
    }

    const updateProjectDescription = (description) => {
        if (description === '') {
            errorToast("A descrição não pode estar vazia!");
            return;
        }
        axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, { description }, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                if (response.status === 200) {
                    successToast("Descrição atualizada com sucesso!");
                    setDescriptionEditable(false);
                    setEditedDescription('');
                    fetchProject();
                }
            })
            .catch(error => {
                errorToast("Houve um erro ao atualizar a descrição do projeto:", error);
            });
    }

    const updateDesiredValue = (desiredValue) => {
        if (desiredValue === '') {
            errorToast("O valor pretendido não pode estar vazio!");
            return;
        }

        axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, { desiredValue }, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                if (response.status === 200) {
                    successToast("Valor pretendido atualizado com sucesso!");
                    setDesiredValueEditable(false);
                    setEditedDesiredValue('');
                    fetchProject();
                }
            })
            .catch(error => {
                errorToast("Houve um erro ao atualizar o valor pretendido do projeto:", error);
            });
    }

    const updateAudio = () => {
        if (editedAudio) {
            const audio = new Audio();
            audio.src = URL.createObjectURL(editedAudio);

            audio.addEventListener('loadedmetadata', () => {
                if (audio.duration > 300) {
                    errorToast("O áudio não pode ter mais de 5 minutos");
                    return;
                } else {

                    setIsUpdatingAudio(true);
                    const formData = new FormData();
                    formData.append("audio", editedAudio);

                    axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, formData, {
                        headers: {
                            "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                            "Content-Type": "multipart/form-data"
                        }
                    })
                        .then(response => {
                            successToast("Áudio atualizado com sucesso!");
                            fetchProject()
                        })
                        .catch(error => {
                            errorToast("Houve um erro ao atualizar o áudio do projeto:", error);
                        })
                        .finally(() => {
                            setIsUpdatingAudio(false)
                        });
                }
            });
        }
    };

    const handleProjectPictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Update the project picture with the selected file
            updateProjectPicture(file);
        }
    };

    const updateProjectPicture = (file) => {
        const formData = new FormData();
        formData.append("picture", file);

        axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                successToast("Foto atualizada com sucesso!");
                fetchProject();  // fetch the project again to update the picture
            })
            .catch(error => {
                errorToast("Houve um erro ao atualizar a foto do projeto:", error);
            });
    };

    const addDocuments = (file) => {
        const formData = new FormData();
        selectedDocuments.forEach((document, index) => {
            formData.append(`documents`, document);  // Adding the selected documents to the form data
        });

        setIsAddingDocuments(true);

        axios.patch(`https://fundeia.pt/server/project/editproject/${projectId}`, formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                successToast("Documentos adicionados com sucesso!");
                fetchProject();  // fetch the project again to update the picture
            })
            .catch(error => {
                errorToast("Houve um erro ao adicionar documentos:", error);
            }).finally(() => {
                setIsAddingDocuments(false);
                setSelectedDocuments([]);
            });
    };

    const makePrivate = (document) => {
        axios.patch(`https://fundeia.pt/server/project/documentvisibility/${projectId}`, { private: true, documentId: document._id }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                successToast("Documento tornado privado com sucesso!");
                fetchProject();  // fetch the project again to update the picture
            })
            .catch(error => {
                errorToast("Houve um erro ao tornar o documento privado:", error);
            });
    };

    const makePublic = (document) => {
        axios.patch(`https://fundeia.pt/server/project/documentvisibility/${projectId}`, { private: false, documentId: document._id }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
        })
            .then(response => {
                successToast("Documento tornado público com sucesso!");
                fetchProject();  // fetch the project again to update the picture
            })
            .catch(error => {
                errorToast("Houve um erro ao tornar o documento público:", error);
            });
    };

    const deleteDocument = (document) => {
        axios.delete(`https://fundeia.pt/server/project/deletedocument/${projectId}`,
            {
                data: { documentId: document._id },
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                }
            }
        )
            .then(response => {
                successToast("Documento eliminado com sucesso!");
                fetchProject();
            })
            .catch(error => {
                errorToast("Houve um erro ao eliminar o documento:", error);
            });
    }

    const publishProject = () => {
        if (window.confirm("Tem a certeza que quer publicar este projeto?")) {
            axios.patch(`https://fundeia.pt/server/project/publish/${projectId}`, { status: 'open' }, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
                .then(response => {
                    if (response.status === 200) {
                        successToast("Projeto publicado com sucesso!");
                        fetchProject();
                    }
                })
                .catch(error => {
                    errorToast("Houve um erro ao publicar o projeto:", error);
                });
        }
    }


    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="containerNavBar bg-gray-100 relative pt-[68px]">
            {projectId === undefined ? <h1 className='pt-32 text-lg'>Entre no projeto pelo feed</h1> :
                <div className="flex w-5/6 sm:w-3/5 mx-auto">
                    <div className="bg-white p-4 my-10 rounded-md shadow-lg w-full mx-auto h-full relative">
                        <div className='items-center mb-6'>
                            {project.status !== "private" && <button onClick={navigateToAuctionPage} className='mt-1 -mb-2 text-white text-sm sm:text-base bg-primary p-2 rounded-lg hover:bg-primary-hover font-bold'>
                                Página do Leilão
                            </button>}
                            {project.status === "private" && <p className="font-bold text-primary text-center -mt-1 text-lg">Privado</p>}
                            {project.status === "private" && <button className='absolute top-4 right-4' onClick={deleteProject}>
                                <BsTrashFill className='h-5 w-5' />
                            </button>}
                        </div>
                        <div className='mx-5 md:mx-10 mt-2'>
                            <div className='flex flex-row items-center mb-4'>
                                <label className={`${project.status === "private" ? 'cursor-pointer' : null}`}>
                                    <img
                                        src={project.projectPicture}
                                        alt="project"
                                        className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full"
                                    />
                                    {project.status === "private" && <input
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={handleProjectPictureChange}
                                    />}
                                </label>
                                <div className='flex flex-col ml-3 justify-between'>
                                    <div className='flex flex-row'>
                                        {project.status === 'private' && (
                                            <>
                                                {isTitleEditable ? (
                                                    <div className='text-start w-2/3'>
                                                        <input
                                                            value={editedTitle}
                                                            placeholder={project.title}
                                                            onChange={(e) => setEditedTitle(e.target.value)}
                                                            className="text-base lg:text-lg w-full font-bold text-left border-b-2 border-primary focus:outline-none"
                                                        />
                                                    </div>
                                                ) : (
                                                    <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>
                                                )}
                                                {!isTitleEditable ? (
                                                    <button className="ml-2" onClick={() => setTitleEditable(true)}>
                                                        <AiFillEdit size={20} />
                                                    </button>
                                                ) : (
                                                    <button className="ml-2" onClick={() => cancelEditable()}>
                                                        <MdCancel size={22} />
                                                    </button>
                                                )}
                                                {isTitleEditable && (
                                                    <button className='ml-2' onClick={() => updateProjectTitle(editedTitle)}>
                                                        <BsFillCheckSquareFill size={18} />
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        {project.status !== 'private' && <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>}
                                    </div>
                                    {isMobile && <div className='self-start items-center'> <Rating rating={project.averageRating} /> </div>}
                                </div>
                                {!isMobile && (
                                    <div className='ml-auto self-start mt-0.5 flex flex-row items-center'>
                                        <Rating rating={project.averageRating} />
                                    </div>)}
                            </div>
                            <div className="text-justify text-sm flex">
                                {project.status === 'private' && (
                                    <>
                                        {isDescriptionEditable ? (
                                            <div className='text-start w-full'>
                                                <textarea
                                                    value={editedDescription}
                                                    placeholder='Descrição'
                                                    onChange={(e) => setEditedDescription(e.target.value)}
                                                    className="text-base text-left border-b-2 border-primary focus:outline-none w-full resize-none"
                                                    rows={isMobile ? "5" : "3"}
                                                />
                                            </div>
                                        ) : (
                                            <p>{project.description}</p>
                                        )}
                                        {!isDescriptionEditable ? (
                                            <button onClick={() => setDescriptionEditable(true)} className='ml-2'>
                                                <AiFillEdit size={20} />
                                            </button>
                                        ) : (
                                            <div className='flex flex-row ml-2'>
                                                <button onClick={() => cancelDescriptionEditable()}><MdCancel size={22} /></button>
                                                <button onClick={() => updateProjectDescription(editedDescription)} className='ml-1'>
                                                    <BsFillCheckSquareFill size={18} />
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                                {project.status !== 'private' && <p>{project.description}</p>}
                            </div>
                        </div>
                        {/* Bid Container */}
                        <div className="mt-6 bg-gray-50 p-4 rounded-md shadow-lg mx-5 md:mx-10">
                            <h3 className="text:base md:text-xl font-bold">Licitações</h3>
                            <p className="text-primary text-sm md:text-base text-center my-2 flex flex-row justify-center items-center">
                                <strong>Valor Pretendido:</strong>
                                {project.status === "private" && (
                                    <>
                                        {isDesiredValueEditable ? (
                                            <input
                                                type="number"
                                                value={editedDesiredValue}
                                                placeholder={project.desiredValue}
                                                onChange={(e) => {
                                                    // This regex checks if the input value is a number with up to two decimal places
                                                    if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                                                        setEditedDesiredValue(e.target.value);
                                                    }
                                                }}
                                                className="text-sm md:text-base border-b-2 border-primary focus:outline-none w-1/2 lg:w-1/6 inline-block text-center ml-1"
                                            />
                                        ) : (
                                            <>
                                                <span className="ml-1"></span>
                                                <strong>{project.desiredValue.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' })}</strong>
                                            </>
                                        )}
                                        {isDesiredValueEditable ? (
                                            <span className="ml-2 flex">
                                                <button onClick={() => cancelDesiredValueEditable()}>
                                                    <MdCancel size={22} />
                                                </button>
                                                <button onClick={() => updateDesiredValue(editedDesiredValue)} className='ml-1'>
                                                    <BsFillCheckSquareFill size={18} />
                                                </button>
                                            </span>
                                        ) : (
                                            <button className="ml-2 inline-block" onClick={() => setDesiredValueEditable(true)}>
                                                <AiFillEdit size={20} />
                                            </button>
                                        )}
                                    </>
                                )}
                                {project.status !== "private" && <><span className="ml-1"></span><strong>{project.desiredValue.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' })}</strong></>}
                            </p>
                            {project && project.bids.length > 0 ? (
                                project.bids
                                    .sort((a, b) => b.amount - a.amount)
                                    .slice(0, 5)
                                    .map((bid, index) => (
                                        <div key={index} className="py-2 border-b mb-2 flex flex-row justify-between items-center space-x-2">
                                            <p className="font-semibold text-sm md:text-base">{bid.user.name}</p>
                                            <p className="font-bold text-base md:text-lg text-primary">
                                                {bid.amount.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' })}
                                            </p>
                                        </div>
                                    ))
                            ) : (
                                <p className="text-gray-500">Ainda não houve licitações</p>
                            )}
                        </div>

                        <div>
                            <div className="flex justify-center align-center mt-8">
                                <audio ref={audioRef}>
                                    <source src={audioUrl} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                            {isAudioReady && <div className="bg-gray-100 w-4/5 md:w-2/4 lg:w-1/3 px-6 pt-6 pb-4 mx-auto rounded-md shadow-lg">
                                <div className="flex items-center justify-center space-x-2">
                                    <button onClick={() => (isPlaying ? pauseAudio() : playAudio())} className='-ml-2'>
                                        {isPlaying ? <BsFillPauseFill className='h-6 w-6' /> : <BsFillPlayFill className='h-6 w-6' />}
                                    </button>
                                    <div className="relative h-4 rounded-md bg-gray-300 mx-auto w-4/5">
                                        {audioRef.current && <div className="h-full bg-primary rounded-md" style={{ width: `${(currentAudioDuration / audioRef.current.duration) * 100}%`, minWidth: '2%' }}></div>}
                                        {audioRef.current && <input
                                            type="range"
                                            min="0"
                                            max={audioRef.current.duration}
                                            step="2"
                                            value={currentAudioTime}
                                            onChange={handleSliderChange}
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                        />}
                                    </div>
                                </div>
                                {audioRef.current ? <p className="text-black text-sm mt-2">
                                    {formatTime(currentAudioDuration)}
                                </p> : <p className="text-black text-sm mt-2">0:00</p>}
                            </div>}
                            {project.status === "private" && <div className="mb-4">
                                <div className="flex items-center justify-center mt-4">
                                    <label
                                        className="w-2/3 sm:1/2 md:w-1/3 flex justify-center py-2 px-4 border border-primary rounded-md cursor-pointer bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200"
                                    >
                                        Alterar Áudio
                                        <input
                                            type="file"
                                            name="audio"
                                            accept="audio/*"
                                            className="sr-only"
                                            onChange={handleEditAudioChange} // Changed the handler to handleEditAudioChange
                                        />
                                    </label>
                                </div>
                                {editedAudio && (
                                    <div className="flex items-center justify-center mt-2 text-gray-600">
                                        <span className="">{editedAudio.name}</span> {/* Display the name of the edited audio file */}
                                    </div>
                                )}
                                {editedAudio && (
                                    <div className="flex items-center justify-center mt-2">
                                        <button
                                            onClick={updateAudio}
                                            className="bg-primary text-white py-2 px-4 rounded-lg cursor-pointer hover:bg-primary-hover transition duration-300"
                                        >
                                            {isUpdatingAudio ? 'Atualizando...' : 'Atualizar áudio'}
                                        </button>
                                    </div>
                                )}
                            </div>}
                        </div>

                        <div className="mb-6 mt-8">
                            <h2 className="text-base md:text-lg font-bold mb-2">Documentos</h2>
                            {project && project.documentUrls.length > 0 ? (
                                null
                            ) : (
                                <p className="text-gray-600 mb-4">Não há documentos</p>
                            )}
                            <div className="items-center justify-center">
                                <label
                                    className="w-2/3 md:w-1/3 mx-auto flex justify-center py-2 px-4 border border-primary rounded-md cursor-pointer bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200"
                                >
                                    {selectedDocuments.length
                                        ? `Selecionado ${selectedDocuments.length} arquivo(s)`
                                        : "Adicionar Documentos"}
                                    <input
                                        type="file"
                                        name="documents"
                                        accept=".pdf, .jpg, .jpeg, .png"
                                        multiple
                                        className="sr-only"
                                        onChange={handleDocumentChange}
                                    />
                                </label>
                            </div>

                            {selectedDocuments.length > 0 && (
                                <div className="flex flex-col items-center mt-2 text-gray-600 overflow-y-auto max-h-28 pt-1">
                                    {selectedDocuments.map((document, index) => (
                                        <span className="mb-1" key={index}>
                                            {document.name}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {selectedDocuments.length > 0 && (
                                <div className="flex items-center justify-center mt-2">
                                    <button className="bg-primary hover:bg-primary-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled={isAddingDocuments} onClick={() => addDocuments()}>
                                        {isAddingDocuments ? 'Adicionando...' : 'Adicionar documentos'}
                                    </button>
                                </div>
                            )}
                        </div>

                        {project && project.documentUrls.filter(document => document.private).length > 0 && (
                            <div className="flex flex-col mb-4 mx-5 md:mx-10">
                                <h3 className="text-sm md:text-base font-bold text-gray-800">Privados</h3>
                                <div className={`grid gap-4 mt-4 mx-auto relative ${project.documentUrls.filter(document => document.private).length === 1 ? 'place-items-center grid-cols-1' : project.documentUrls.filter(document => document.private).length === 2 ? 'place-items-center grid-cols-2' : project.documentUrls.filter(document => document.private).length === 3 ? 'place-items-center grid-cols-3' : 'grid-cols-2 sm:grid-cols-4'}`}>
                                    {project.documentUrls.filter(document => document.private).map((document, index) => {
                                        const isPDF = document.path?.endsWith('.pdf');
                                        return (
                                            <div
                                                key={index}
                                                className="p-1 border rounded-md flex flex-col items-center justify-center hover:bg-gray-100 transition duration-300 h-24 sm:h-36 w-full relative"
                                                onClick={() => openModal(document)}
                                            >
                                                {isPDF ? (
                                                    <div className="flex items-center justify-center">
                                                        <AiFillFilePdf size={50} />
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={document.path}
                                                        alt={`Image Document ${index + 1}`}
                                                        className="object-contain w-full h-full"
                                                    />
                                                )}
                                                {isPDF && <p className='text-sm md:text-base mt-2 px-[53px]'>PDF</p>}
                                                {
                                                    selectedDocument === document && isModalOpen && (
                                                        <div className="modal absolute top-0 left-0 bg-white p-2 rounded shadow-lg z-10">
                                                            <div>
                                                                <a
                                                                    href={selectedDocument.path}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className='block py-2 w-full text-center hover:bg-gray-100'
                                                                >
                                                                    Ver
                                                                </a>
                                                                <button
                                                                    className='py-2 w-full hover:bg-gray-100'
                                                                    onClick={() => makePublic(selectedDocument)}
                                                                >
                                                                    Tornar Público
                                                                </button>
                                                                <button
                                                                    className='py-2 w-full hover:bg-gray-100'
                                                                    onClick={() => deleteDocument(selectedDocument)}
                                                                >
                                                                    Eliminar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {/* documentos e fotos */}
                        {project && project.documentUrls.filter(document => !document.private).length > 0 ? (
                            <div className="flex flex-col mb-4 mx-5 md:mx-10">
                                <h3 className="text-sm md:text-base font-bold text-gray-800">Públicos</h3>
                                <div className={`grid gap-4 mt-4 mx-auto ${project.documentUrls.filter(document => !document.private).length === 1 ? 'place-items-center grid-cols-1' : project.documentUrls.filter(document => !document.private).length === 2 ? 'place-items-center grid-cols-2' : project.documentUrls.filter(document => !document.private).length === 3 ? 'place-items-center grid-cols-3' : 'grid-cols-2 sm:grid-cols-4'}`}>
                                    {project.documentUrls.filter(document => !document.private).map((document, index) => {
                                        const isPDF = document.path?.endsWith('.pdf');
                                        return (
                                            <div
                                                key={index}
                                                className="p-1 border rounded-md flex flex-col items-center justify-center hover:bg-gray-100 transition duration-300 h-24 sm:h-36 w-full relative"
                                                onClick={() => openModal(document)}
                                            >
                                                {isPDF ? (
                                                    <div className="flex items-center justify-center">
                                                        <AiFillFilePdf size={50} />
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={document.path}
                                                        alt={`Image Document ${index + 1}`}
                                                        className="object-contain w-full h-full"
                                                    />
                                                )}
                                                {isPDF && <p className='text-sm md:text-base mt-2 px-[53px]'>PDF</p>}
                                                {
                                                    selectedDocument === document && isModalOpen && (
                                                        <div className="modal absolute top-0 left-0 bg-white p-2 rounded shadow-lg z-10">
                                                            <div>
                                                                <a
                                                                    href={selectedDocument.path}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className='block py-2 w-full text-center hover:bg-gray-100'
                                                                >
                                                                    Ver
                                                                </a>
                                                                <button
                                                                    className='py-2 w-full hover:bg-gray-100'
                                                                    onClick={() => makePrivate(selectedDocument)}
                                                                >
                                                                    Tornar Privado
                                                                </button>
                                                                <button
                                                                    className='py-2 w-full hover:bg-gray-100'
                                                                    onClick={() => deleteDocument(selectedDocument)}
                                                                >
                                                                    Eliminar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            null
                        )}

                        {project.status === "private" && <button className='publish-button mt-4 mb-2 font-bold' onClick={publishProject}>
                            Publicar Projeto
                        </button>}
                    </div>
                </div>}
        </div>
    );
}

export default MyProjectPage;