import "./InfoPage.css";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaProjectDiagram, FaExchangeAlt } from 'react-icons/fa'; // Example icons

function InfoPage() {
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [closedProjects, setClosedProjects] = useState([]);
    const [openProjects, setOpenProjects] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);

    const getUsers = async () => {
        try {
            const response = await axios.get("https://fundeia.pt/server/user/allusers", { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } });
            setUsers(response.data);

            // Calculate total transactions
            const total = response.data.reduce((acc, user) => acc + (user.transactionCount || 0), 0);
            setTotalTransactions(total);
        } catch (error) {
            console.log(error);
        }
    }

    const getProjects = async () => {
        try {
            const response = await axios.get("https://fundeia.pt/server/project/allprojects", { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } });
            setProjects(response.data);
            setClosedProjects(response.data.filter(project => project.status === "closed"));
            setOpenProjects(response.data.filter(project => project.status === "open"));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUsers();
        getProjects();
    }, []);

    const InfoCard = ({ title, value, icon }) => (
        <div className="info-card flex flex-col items-center p-4 border border-gray-300 rounded-lg shadow-md">
            <div className="icon text-2xl sm:text-3xl mb-2">{icon}</div>
            <p className="title font-semibold text-base sm:text-lg mb-1">{title}</p>
            <p className="value text-lg sm:text-xl font-bold">{value}</p>
        </div>
    );

    return (
        <div className="containerNavBar bg-gray-100 pt-[68px]">
            <div className="flex flex-wrap justify-center gap-5 w-full mx-auto p-4 mt-5 sm:mt-10">
                <InfoCard
                    title="Número de utilizadores registados"
                    value={users.length}
                    icon={<FaUser />}
                />
                <InfoCard
                    title="Número de projetos publicados"
                    value={projects.length}
                    icon={<FaProjectDiagram />}
                />
                <InfoCard
                    title="Número de projetos abertos"
                    value={openProjects.length}
                    icon={<FaProjectDiagram />}
                />
                <InfoCard
                    title="Número de projetos fechados"
                    value={closedProjects.length}
                    icon={<FaProjectDiagram />}
                />
                <InfoCard
                    title="Total de Transações"
                    value={totalTransactions}
                    icon={<FaExchangeAlt />}
                />
            </div>
        </div>
    );
}

export default InfoPage;
