import "./LoginPage.css";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import authService from "../../services/auth.service";
import { FiUser } from 'react-icons/fi';
import { MdLockOutline } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { successToast, errorToast } from '../../components/Toast/Toast';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [emailForOtp, setEmailForOtp] = useState("");
  const [otp, setOtp] = useState("");
  const [otpMessage, setOtpMessage] = useState("");
  const [step, setStep] = useState(1);
  const [newPassword, setNewPassword] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpEmail, setOtpEmail] = useState("");
  const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const { storeToken, authenticateUser } = useContext(AuthContext);

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const handleOTP = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) { // Restrict to 9 digits
      setOtp(inputValue);
    }
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setIsOtpModalVisible(true);
  }

  function validatePassword(password) {
    // Check for minimum length
    const minLength = 8;
    if (password.length < minLength) {
      return `Palavra-passe deve conter pelo menos ${minLength} caracteres.`;
    }

    // Check for at least one number
    if (!/[0-9]/.test(password)) {
      return "Palavra-passe deve conter pelo menos um número.";
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return "Palavra-passe deve conter pelo menos uma letra maiúscula.";
    }

    // Check for at least one special character
    const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!specialCharacters.test(password)) {
      return "Palavra-passe deve conter pelo menos um caracter especial.";
    }

    return null;
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const requestBody = { email, password, rememberMe: isRememberMeChecked };

    authService
      .login(requestBody)
      .then((response) => {
        // If the POST request is successful store the authentication token,
        // after the token is stored authenticate the user
        // and at last navigate to the home page
        storeToken(response.data.authToken);
        authenticateUser();
      })
      .catch((error) => {
        // If the request resolves with an error, set the error message in the state
        const errorDescription = error.response.data.message;
        setErrorMessage(errorDescription);
      });
  };

  const requestOtp = (e) => {
    e.preventDefault();
    const requestBody = { emailForOtp };

    authService
      .forgotPasswordOTP(requestBody)
      .then((response) => {
        setOtpEmail(response.data.otp);
        setOtpMessage("");
        setStep(2);
      })
      .catch((error) => {
        setOtpMessage("Utilizador não encontrado");
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (otp.toString() === otpEmail.toString()) {
      setIsOtpVerified(true);
      setOtpMessage("");
      setStep(3);
    } else {
      setOtpMessage("O código não corresponde. Por favor tente novamente.");
    }
  };

  const resetPassword = (e) => {
    e.preventDefault();
    const error = validatePassword(newPassword);
    if (error) return setOtpMessage(error);

    const requestBody = { password: newPassword, email: emailForOtp };

    // API call to reset password
    authService
      .resetPassword(requestBody)
      .then((response) => {
        successToast("Palavra-passe alterada com sucesso!");
        cancel();
      })
      .catch((error) => {
        setOtpMessage("Ocorreu um erro ao alterar a palavra-passe.")
      });
  };

  const cancel = () => {
    setIsOtpModalVisible(false);
    setStep(1);
    setOtpMessage("");
    setOtp("");
    setNewPassword("");
    setEmailForOtp("");
    setOtpEmail("");
    setIsOtpVerified(false);
  };

  return (
    <div className="flex items-center justify-center h-screen LoginPage bg-primary">
      {isOtpModalVisible && (
        <div className="fixed inset-0 z-20">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all text-center p-10 w-full max-w-[335px] items-center">
              {step === 1 && (
                <div className="w-full">
                  <h3 className="mb-2 text-lg font-medium text-gray-900" id="modal-title">
                    Introduza o seu email
                  </h3>
                  <form onSubmit={requestOtp} className="space-y-4">
                    <div>
                      <input type="email" value={emailForOtp} onChange={(e) => setEmailForOtp(e.target.value)} required className="w-full px-3 py-2 mt-1 border rounded-lg" />
                    </div>
                    <p className="text-sm">Será enviado um código para o seu email</p>
                    {otpMessage && <p className="text-sm text-red-500 text-wrap">{otpMessage}</p>}
                    <div className="mt-5">
                      <button type="submit" className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none">
                        Seguinte
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {step === 2 && (
                <div className="w-full">
                  <h3 className="mb-2 text-lg font-medium text-gray-900" id="modal-title">
                    Verificar o código
                  </h3>
                  <form onSubmit={verifyOtp} className="space-y-4">
                    <div>
                      <input type="number" value={otp} onChange={handleOTP} required className="w-full px-3 py-2 mt-1 border rounded-lg" />
                    </div>
                    {otpMessage && <p className="mt-2 text-sm text-red-500 text-wrap">{otpMessage}</p>}
                    <div className="mt-5">
                      <button type="submit" className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none">
                        Seguinte
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {step === 3 && (
                <div className="w-full">
                  <h3 className="mb-2 text-lg font-medium text-gray-900" id="modal-title">
                    Alterar palavra-passe
                  </h3>
                  <form onSubmit={resetPassword} className="space-y-4">
                    <div className="relative">
                      <input type={isForgotPasswordVisible ? "text" : "password"} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required className="w-full py-2 pl-4 pr-10 mt-1 border rounded-lg" placeholder="Nova palavra-passe" />
                      <span
                        onClick={() => setIsForgotPasswordVisible(!isForgotPasswordVisible)}
                        className="absolute top-[25px] transform -translate-y-1/2 right-3 text-black z-10 cursor-pointer text-xl"
                      >
                        {isForgotPasswordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                      </span>
                    </div>
                    {otpMessage && <p className="mt-2 text-sm text-red-500 text-wrap">{otpMessage}</p>}
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button type="submit" className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none">
                        Alterar
                      </button>
                    </div>
                  </form>
                </div>
              )}

              <button onClick={() => cancel()} type="button" className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
                Cancelar
              </button>

            </div>
          </div>
        </div>
      )}
      <div className="flex w-full max-w-5xl py-10 mx-4 bg-white border divide-gray-200 rounded-lg min-h-2/3 sm:divide-x-2">

        {/* Left Side Content */}
        <div className="hidden w-1/2 px-8 sm:block">
          <img src="/fundeia.png" alt="Logo" className="w-24 h-24 mx-auto mb-6" />
          <p className="text-sm text-center sm:px-14">UMA COMUNIDADE SEGURA</p>
          <p className="text-sm">+</p>
          <p className="text-sm text-center sm:px-14">UM MERCADO EXCLUSIVO</p>
          <p className="text-sm">+</p>
          <p className="text-sm text-center sm:px-14">UMA NOVA ALTERNATIVA DE FINANCIAMENTO!</p>
          <br />
          <p className="text-sm text-center sm:px-12"><strong>O NEGÓCIO DA SUA VIDA PODE ESTAR AQUI, NA FUNDEIA.</strong></p>
          <img src="/loginSignUpImage.jpg" alt="Login and Sign Up" className="w-full" />
        </div>

        {/* Right Side Login */}
        <div className="justify-between p-8 mx-auto bg-white sm:flex sm:flex-col sm:w-1/2 w-1/1">
          <div>
            <div className="flex flex-row justify-center mx-auto mb-8 -mt-8 sm:w-3/4 md:w-4/6 sm:mb-12">
              <Link to="/login" className={`py-2 sm:w-2/4 md:w-2/6 w-1/3 text-white rounded bg-primary text-sm font-bold`}>
                Login
              </Link>
              <Link to="/signup" className={`py-2 sm:w-2/4 w-1/3 md:w-2/6 text-white rounded bg-gray-400 text-sm -ml-1 z-10 font-bold`}>
                Sign Up
              </Link>
            </div>

            <div>
              <div className="flex mx-auto mb-10 sm:w-3/4 md:w-4/6">
                <h1 className="relative font-bold text-md">
                  Login
                  <div className="absolute -bottom-2 left-0 inset-x-4 h-0.5 bg-primary"></div>
                </h1>
              </div>

              <form onSubmit={handleLoginSubmit} className="space-y-4">
                <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                  <FiUser className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleEmail}
                    autoComplete="email"
                    className="w-full py-2 pl-8 pr-4 text-sm border border-gray-400 rounded-l"
                    placeholder="Email"
                  />
                </div>

                <div className="relative flex flex-col items-center mx-auto sm:w-3/4 md:w-4/6 max-w-3/4">
                  <MdLockOutline className="absolute z-10 text-black transform -translate-y-1/2 top-1/2 left-3" />
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handlePassword}
                    autoComplete="current-password"
                    className="w-full px-8 py-2 pr-10 text-sm border border-gray-400 rounded-l"
                    placeholder="Palavra-Passe"
                  />
                  <span
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    className="absolute z-10 text-xl text-black transform -translate-y-1/2 cursor-pointer top-1/2 right-3"
                  >
                    {isPasswordVisible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-4 mx-auto text-sm sm:w-6/7 md:w-4/6">
                  <div className="flex justify-start">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      checked={isRememberMeChecked}
                      onChange={() => setIsRememberMeChecked(prev => !prev)}
                      className="hidden checkbox-primary"
                    />
                    <label htmlFor="rememberMe" className="cursor-pointer checkbox-label"></label>
                    <label htmlFor="rememberMe" className="cursor-pointer">Lembrar</label>
                  </div>
                  <button type="button" className="flex justify-end text-right" onClick={handleForgotPasswordClick}>Esqueceu Password?</button>
                </div>

                <div className="pt-3">
                  <button type="submit" className="w-3/4 p-2 font-bold text-white rounded-md bg-primary hover:bg-primary-hover sm:w-2/4">Login</button>
                </div>
              </form>
            </div>
          </div>
          {errorMessage && <p className="mt-4 text-red-500 error-message">{errorMessage}</p>}


          <div className="flex flex-row justify-between mx-auto mt-10 space-x-4 sm:w-6/7 md:w-4/6">
            <a href="https://drive.google.com/file/d/1RkrFGr8q4KYLM77doAWZGyy58Is3mwVZ/view?usp=drivesdk" className="flex items-center justify-center text-sm text-black">
              <span>Confidencialidade</span>
            </a>

            <a href="https://drive.google.com/file/d/1KVgmI43bEIidsKnhJ4OqKop-1-0Gzgwq/view?usp=drivesdk" className="flex items-center justify-center text-sm text-black">
              <span>Manual de Utilização</span>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}

export default LoginPage;
