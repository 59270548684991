import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from "../../context/auth.context";
import './ProfilePage.css';
import axios from 'axios';
import { BsArrowLeft, BsHouseDoor, BsShieldLock } from 'react-icons/bs';
import { successToast, errorToast } from '../../components/Toast/Toast';
import { FiUser, FiPhone } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { MdLockOutline } from 'react-icons/md';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Loading from "../../components/Loading/Loading";

function ProfilePage() {
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useContext(AuthContext);
  const [name, setName] = useState(user.name);
  const [newEmail, setNewEmail] = useState(null);
  const [email, setEmail] = useState(user.email);
  const [address, setAddress] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [profilePic, setProfilePic] = useState(user.profilePicture);
  const fileInputRef = useRef(null);
  const [isPasswordVisible1, setIsPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [otp, setOtp] = useState(null);
  const [otpUser, setOtpUser] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requestedFunds, setRequestedFunds] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addFunds = async () => {
    setIsSubmitting(true);
    try {
      const res = await axios.patch(`https://fundeia.pt/server/user/addFunds/${user._id}`, {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      if (res.data.success) {
        setRequestedFunds(true)
        successToast("1000€ adicionados com sucesso!");
        getUser();
      } else {
        errorToast("Ocorreu um erro ao adicionar 1000€.");
      }
    } catch (err) {
      errorToast("Ocorreu um erro ao adicionar 1000€.");
    }
    setIsSubmitting(false);
  };

  const handleOTP = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 6) { // Restrict to 9 digits
      setOtpUser(inputValue);
    }
  };

  const [updatedUser, setUpdatedUser] = useState({
    email: user.email,
    password: null,
    profilePicture: user.profilePicture,
    currentPassword: ''
  });

  useEffect(() => {
    setUpdatedUser(prev => ({
      ...prev,
      email: newEmail ? newEmail : user.email,
      password: password,
      currentPassword: currentPassword
    }));
  }, [newEmail, password, currentPassword]);

  const hasChanges = () => {
    return (updatedUser.email !== email) ||
      (updatedUser.password) ||
      (updatedUser.profilePicture !== user.profilePicture)
  };

  const getUser = async () => {
    try {
      const res = await axios.get(`https://fundeia.pt/server/user/${user._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })

      const newUser = res.data;

      setName(newUser.name);
      setEmail(newUser.email);
      setAddress(newUser.address);
      setPhone(newUser.phone);
      setProfilePic(newUser.profilePicture);
      setRequestedFunds(newUser.requestedFunds)
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const editUser = async () => {
    try {
      if (!hasChanges()) {
        setPasswordError("Nenhuma alteração detectada");
        return;
      }

      if (newEmail && !showOTP) {
        errorToast("Verifique o seu email para introduzir o código OTP.");
        return;
      }

      if (showOTP) {
        if (otpUser.toString() !== otp.toString()) {
          errorToast("Código OTP inválido.");
          return;
        }
      }

      const res = await axios.patch(
        `https://fundeia.pt/server/user/${user._id}`,
        updatedUser,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      // Check the response to see if the password was verified
      if (res.data.success) {
        const newUser = res.data.updatedUser;  // Assuming the updated user data is returned
        setEmail(newUser.email);
        successToast("Perfil atualizado com sucesso!");
        setIsEditing(false);
        setPasswordError(null);
        setCurrentPassword('');
        setPassword('');
        setNewEmail(null);
        setOtp(null);
        setOtpUser(null);
        setShowOTP(false);
        setUpdatedUser({
          email: newUser.email,
          password: null,
          profilePicture: user.profilePicture,
          currentPassword: ''
        });
      } else {
        setPasswordError(res.data.message);
      }
    } catch (err) {
      errorToast("Ocorreu um erro ao atualizar o perfil.");
    }
  };

  const handleSave = () => {
    const error = validateForm();
    if (error) {
      setPasswordError(error);
      return;
    }

    editUser();
  };

  const validateForm = () => {
    if (!updatedUser.currentPassword) {
      return "Introduza a sua palavra-passe atual";
    }

    if (updatedUser.password) {
      const error = validatePassword(updatedUser.password);
      if (error) return error;
    }

    if (!hasChanges()) {
      return "Introduza um novo email ou uma nova palavra-passe";
    }

    return null;
  };

  function validatePassword(password) {
    // Check for minimum length
    const minLength = 8;
    if (password.length < minLength) {
      return `Palavra-passe deve conter pelo menos ${minLength} caracteres.`;
    }

    // Check for at least one number
    if (!/[0-9]/.test(password)) {
      return "Palavra-passe deve conter pelo menos um número.";
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return "Palavra-passe deve conter pelo menos uma letra maiúscula.";
    }

    // Check for at least one special character
    const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!specialCharacters.test(password)) {
      return "Palavra-passe deve conter pelo menos um caracter especial.";
    }

    return null;
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("picture", file);

      try {
        const res = await axios.post(`https://fundeia.pt/server/profilePicture`, formData, { headers: { "Content-Type": "multipart/form-data" } });
        const imageUrl = res.data;
        setProfilePic(imageUrl);
        setUpdatedUser(prev => ({ ...prev, profilePicture: imageUrl }));
      } catch (err) {
        errorToast("Error uploading image to Cloudinary:", err);
      }
    }
  };

  const sendVerificationEmail = async () => {
    try {

      if (!newEmail) {
        errorToast("Preencha o campo do email.");
        return;
      }

      if (newEmail === email) {
        errorToast("O email introduzido é igual ao atual.");
        return;
      }

      const res = await axios.post(`https://fundeia.pt/server/user/sendVerificationEmail`, { email: newEmail }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (res.status === 200) {
        successToast("Email de verificação enviado com sucesso!");
        setOtp(res.data.otp);
        setShowOTP(true);
      }
    } catch (err) {
      errorToast(err.response.data.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="containerNavBar flex flex-col items-center justify-center bg-gray-100 pt-[68px] h-screen overflow-hidden w-full">
      {requestedFunds === false && <div className="flex justify-center my-4">
        <button
          className="bg-primary hover:bg-primary-hover text-white font-bold py-2 px-4 rounded transition duration-300"
          onClick={addFunds}
          disabled={isSubmitting}
        >
          Adicionar 1000€ à carteira
        </button>
      </div>}
      <div className="bg-white p-8 rounded shadow-md max-w-screen-md w-[85vw] sm:w-3/4 h-[90vh] max-h-[85vh] lg:mx-0 overflow-y-auto relative mb-4">
        <h1 className="text-2xl font-bold mb-4 mx-auto">Perfil</h1>

        {isEditing ? (
          <>
            <form onSubmit={e => e.preventDefault()}>
              <button className='absolute top-0 left-0 mt-7 ml-9' onClick={() => {
                setIsEditing(false);
                setCurrentPassword('');
                setPassword('');
                setPasswordError(null);
                setNewEmail(null);
                setOtp(null);
                setOtpUser(null);
                setShowOTP(false);
              }}>
                <BsArrowLeft className='h-7 w-7' />
              </button>

              <button
                onClick={() => fileInputRef.current.click()}
                className="focus:outline-none rounded-full border-2 border-primary w-32 h-32 mx-auto mb-4 overflow-hidden"
              >
                <img src={profilePic} alt="Profile Preview" className="w-full h-full object-cover rounded mb-4 mx-auto" />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                className="hidden"
              />

            </form>

            <form onSubmit={e => e.preventDefault()}>
              <div className="relative mb-4 p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <label className="text-gray-600 flex items-center ">
                  <span className="mr-2 text-gray-900"><MdLockOutline /></span>
                  <strong className='text-sm sm:text-base text-wrap'>Palavra-Passe Atual:</strong>
                </label>
                <input
                  type={isPasswordVisible1 ? "text" : "password"}
                  value={currentPassword}
                  placeholder="Introduza a sua palavra-passe atual"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="border-1 rounded py-2 pl-2 pr-8 w-full mt-2 focus:outline-none focus:border-primary focus:ring focus:ring-primary text-sm sm:text-base text-wrap"
                />
                <button
                  className="absolute top-[66%] transform -translate-y-1/2 right-6 text-black z-10 cursor-pointer text-xl"
                  onClick={() => setIsPasswordVisible1(!isPasswordVisible1)}
                >
                  {isPasswordVisible1 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </button>
              </div>

              <div className="mb-4 p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <label className="text-gray-600 flex items-center">
                  <span className="mr-2 text-gray-900"><HiOutlineMail /></span>
                  <strong className="text-sm sm:text-base text-wrap">Email:</strong>
                </label>
                <div className="relative">
                  <input
                    type="email"
                    placeholder={email}
                    onChange={(e) => setNewEmail(e.target.value)}
                    className="border-1 rounded w-full mt-2 focus:outline-none focus:border-primary focus:ring focus:ring-primary text-sm sm:text-base text-wrap py-2 pl-2 pr-16"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center bg-primary mt-2 rounded-r pl-3 text-white text-sm hover:bg-primary-hover font-bold"
                    onClick={sendVerificationEmail}
                  >
                    Enviar
                  </button>
                </div>
                {showOTP && (
                  <div className="mt-4">
                    <label className="text-gray-600 flex items-center mt-2">
                      <span className="mr-2 text-gray-900"><BsShieldLock /></span>
                      <strong className="text-sm sm:text-base text-wrap">OTP:</strong>
                    </label>
                    <input
                      type="number"
                      value={otpUser}
                      onChange={handleOTP}
                      placeholder="Insira o código OTP"
                      className="border-1 rounded w-full mt-2 focus:outline-none focus:border-primary focus:ring focus:ring-primary text-sm sm:text-base text-wrap py-2 pl-2"
                    />
                  </div>
                )}
              </div>

              <div className="relative mb-4 p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <label className="text-gray-600 flex items-center">
                  <span className="mr-2 text-gray-900"><MdLockOutline /></span>
                  <strong className='text-sm sm:text-base text-wrap'>Palavra-Passe:</strong>
                </label>
                <input
                  type={isPasswordVisible2 ? "text" : "password"}
                  placeholder="********"
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-1 rounded py-2 pl-2 pr-8 w-full mt-2 focus:outline-none focus:border-primary focus:ring focus:ring-primary text-sm sm:text-base text-wrap"
                />
                <button
                  className="absolute top-[66%] transform -translate-y-1/2 right-6 text-black z-10 cursor-pointer text-xl"
                  onClick={() => setIsPasswordVisible2(!isPasswordVisible2)}
                >
                  {isPasswordVisible2 ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </button>
              </div>

              {passwordError && <p className="text-red-500 text-sm sm:text-base text-wrap">{passwordError}</p>}

              <button onClick={handleSave} className="bg-primary hover:bg-primary-hover text-white p-2 rounded w-2/3 sm:w-1/3 mt-4 font-bold">Guardar</button>
            </form>
          </>
        ) : (
          <>

            <div className="rounded-full border-2 border-primary w-32 h-32 mx-auto mb-4 overflow-hidden">
              <img src={profilePic} alt="Profile Picture" className="w-full h-full object-cover rounded mb-4 mx-auto" />
            </div>

            <div className="mb-6">
              <div className="p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <div className="flex items-center">
                  <span className="mr-2">
                    <FiUser />
                  </span>
                  <strong className="text-gray-600 text-sm sm:text-base">Name:</strong>
                </div>
                <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{name}</p>
              </div>
            </div>

            <div className="mb-6">
              <div className="p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <div className="flex items-center">
                  <span className="mr-2">
                    <HiOutlineMail />
                  </span>
                  <strong className="text-gray-600 text-sm sm:text-base">Email:</strong>
                </div>
                <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{email}</p>
              </div>
            </div>

            <div className="mb-6">
              <div className="p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <div className="flex items-center">
                  <span className="mr-2">
                    <BsHouseDoor />
                  </span>
                  <strong className="text-gray-600 text-sm sm:text-base">Morada:</strong>
                </div>
                <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{address}</p>
              </div>
            </div>

            <div className="mb-6">
              <div className="p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <div className="flex items-center">
                  <span className="mr-2">
                    <FiPhone />
                  </span>
                  <strong className="text-gray-600 text-sm sm:text-base">Telemóvel:</strong>
                </div>
                <p className="mx-auto text-gray-800 text-sm sm:text-base text-wrap">{phone}</p>
              </div>
            </div>

            <div className="mb-6">
              <div className="p-4 bg-gray-100 rounded-md shadow-md sm:w-3/4 mx-auto">
                <div className="flex items-center">
                  <span className="mr-2">
                    <MdLockOutline />
                  </span>
                  <strong className="text-gray-600 text-sm sm:text-base">Palavra-Passe:</strong>
                </div>
                <p className="mx-auto text-gray-800 text-sm sm:text-base">********</p>
              </div>
            </div>

            <button onClick={() => setIsEditing(true)} className="bg-primary hover:bg-primary-hover text-white p-2 rounded w-2/3 sm:w-1/3 mt-4 font-bold">Editar</button>
          </>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;