import "./ProjectsPage.css";
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import axios from 'axios';
import { successToast, errorToast } from '../../components/Toast/Toast';
import { BsPlus } from 'react-icons/bs';
import Rating from "../../components/Rating/Rating";
import { AiOutlineArrowRight } from 'react-icons/ai';
import Countdown from '../../components/Countdown/Countdown';

function ProjectsPage() {
    const [projects, setProjects] = useState([]);
    const { user } = useContext(AuthContext);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [desiredValue, setDesiredValue] = useState("");
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRefs = useRef({});
    const [showMore, setShowMore] = useState({});
    const [currentAudioDuration, setCurrentAudioDuration] = useState(0);
    const [currentAudioProjectId, setCurrentAudioProjectId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const location = useLocation();

    const navigate = useNavigate();

    const resetSearch = () => {
        setSearchQuery('');
        setSearchResults([]);
        navigate('/projects');
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const query = params.get('q');
        if (query !== null) {
            setSearchQuery(query);
        }
    }, [location.search]);

    useEffect(() => {
        if (searchQuery) {
            initiateSearch();
        }
    }, [searchQuery]);

    const initiateSearch = async () => {
        try {
            const response = await axios.get('https://fundeia.pt/server/project/searchMyProjects', {
                params: { q: searchQuery, userId: user._id },
                headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error("Houve um erro ao buscar os projetos:", error);
        }
    };

    const navigateToMyProjectPage = (project) => {
        const projectId = project._id;
        navigate(`/myproject/${project._id}`, { state: { projectId } });
    };

    const handlePictureChange = (e) => {
        const file = e.target.files[0];
        setSelectedPicture(file);
    };

    const handleDocumentChange = (e) => {
        const files = Array.from(e.target.files);
        const existingFileNames = selectedDocuments.map(doc => doc.name);

        const newFiles = files.filter(file => !existingFileNames.includes(file.name));

        setSelectedDocuments(prevDocuments => [...prevDocuments, ...newFiles]);
    };

    const handleAudioChange = (e) => {
        const file = e.target.files[0];
        setSelectedAudio(file);
    };

    const fetchProjects = () => {
        axios.get(`https://fundeia.pt/server/project/userprojects/${user._id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem("authToken")}` } })
            .then(response => {
                setProjects(response.data);
            })
            .catch(error => {
                console.error("Houve um erro ao buscar os projetos:", error);
            });
    }

    useEffect(() => {
        fetchProjects();
    }, []);

    const createProject = async (e) => {
        e.preventDefault();

        if (!title || !description || !desiredValue || !selectedAudio || !selectedPicture) {
            errorToast("Preencha todos os campos");
            return;
        }

        if (selectedAudio) {
            const audio = new Audio();
            audio.src = URL.createObjectURL(selectedAudio);

            // Listen for the 'loadedmetadata' event to get audio duration
            audio.addEventListener('loadedmetadata', () => {
                if (audio.duration > 300) {
                    errorToast("O áudio não pode ter mais de 5 minutos");
                } else {
                    // If audio duration is valid, proceed with project creation
                    uploadProject();
                }
            });
        }
    }

    const uploadProject = () => {
        const formData = new FormData();
        formData.append("audio", selectedAudio);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("desiredValue", desiredValue);
        formData.append("user", user._id);
        formData.append("picture", selectedPicture);
        selectedDocuments.forEach((document, index) => {
            formData.append(`documents`, document);  // Adding the selected documents to the form data
        });

        setIsLoading(true); // Set loading state

        axios.post('https://fundeia.pt/server/project/createproject', formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data" // Set proper content type
            }
        })
            .then(response => {
                fetchProjects();
                successToast("Projeto adicionado com sucesso!");
                setTitle("");
                setDescription("");
                setDesiredValue("");
                setSelectedAudio(null);
                setSelectedDocuments([]);
            })
            .catch(error => {
                console.error("Houve um erro ao criar o projeto:", error);
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state
            });
    }

    // Function to play audio for a specific project
    const playAudio = (projectId) => {
        const audioElement = audioRefs.current[projectId];

        // Pause any currently playing audio
        for (const id in audioRefs.current) {
            if (audioRefs.current[id] !== audioElement) {
                audioRefs.current[id].pause();
            }
        }

        // Play the selected audio
        setCurrentAudioProjectId(projectId);
        audioElement.currentTime = 0;
        setIsPlaying(true);
        audioElement.play();
    };

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    const toggleShowMore = (projectId) => {
        setShowMore(prevState => ({ ...prevState, [projectId]: !prevState[projectId] }));
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const pauseAudio = (projectId) => {
        if (isPlaying) {
            audioRefs.current[projectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            setCurrentAudioDuration(0);
            setCurrentAudioProjectId(null);
            setIsPlaying(false);
            audioRefs.current[projectId].pause();
        }
    };


    const handleAudioTimeUpdate = (e, projectId) => {
        if (currentAudioProjectId === projectId) {
            const currentTime = e.target.currentTime;
            setCurrentAudioDuration(currentTime);
        }
    };

    useEffect(() => {
        if (audioRefs.current[currentAudioProjectId]) {
            audioRefs.current[currentAudioProjectId].addEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
        }

        return () => {
            if (audioRefs.current[currentAudioProjectId]) {
                audioRefs.current[currentAudioProjectId].removeEventListener('timeupdate', (e) => handleAudioTimeUpdate(e, currentAudioProjectId));
            }
        };
    }, [currentAudioProjectId]);

    return (
        <div className="containerNavBar bg-gray-100 pt-[68px]">
            <div className="containerNavBar flex w-5/6 sm:w-3/5 mx-auto space-x-4">

                <div className="mx-auto w-full">
                    <h1 className="text-2xl mt-4 mb-6 font-semibold">Meus Projetos</h1>
                    {showCreateProject ? <div className="mb-8 py-6 px-6 w-1/1 sm:w-4/5 lg:w-3/5 mx-auto md:px-20 bg-white rounded-md shadow-lg">
                        <form onSubmit={createProject}>
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Título</label>
                                <input
                                    type="text"
                                    placeholder="Título"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Descrição</label>
                                <textarea
                                    placeholder="Descrição"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className=" resize-none shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-24"
                                ></textarea>
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Valor Pretendido</label>
                                <input
                                    type="number"
                                    placeholder="Valor Pretendido"
                                    value={desiredValue}
                                    onChange={(e) => {
                                        // This regex checks if the input value is a number with up to two decimal places
                                        if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                                            setDesiredValue(e.target.value);
                                        }
                                    }}
                                    step="0.01"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Imagem do Projeto</label>
                                <div className="flex items-center justify-center">
                                    <label className="w-1/2 flex justify-center py-2 px-4 border border-primary rounded-md cursor-pointer bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200">
                                        {selectedPicture ? 'Alterar Imagem' : 'Adicionar Imagem'}
                                        <input
                                            type="file"
                                            name="picture"
                                            accept="image/*"
                                            className="sr-only"
                                            onChange={handlePictureChange}
                                        />
                                    </label>
                                </div>
                                {selectedPicture && (
                                    <div className="flex items-center justify-center mt-2 text-gray-600">
                                        <span className="">{selectedPicture.name}</span>
                                    </div>
                                )}
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Áudio</label>
                                <div className="flex items-center justify-center">
                                    <label className="w-1/2 flex justify-center py-2 px-4 border border-primary rounded-md cursor-pointer bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200">
                                        {selectedAudio ? 'Alterar Áudio' : 'Adicionar Áudio'}
                                        <input
                                            type="file"
                                            name="audio"
                                            accept="audio/*"
                                            className="sr-only"
                                            onChange={handleAudioChange}
                                        />
                                    </label>
                                </div>
                                {selectedAudio && (
                                    <div className="flex items-center justify-center mt-2 text-gray-600">
                                        <span className="">{selectedAudio.name}</span>
                                    </div>
                                )}
                            </div>

                            <div className="mb-6">
                                <label className="block text-sm font-bold mb-1">Documentos</label>
                                <label className="text-xs">*Os documentos serão públicos por predefinição, pode alterar a visibilidade na página de gestão do projeto.</label>
                                <div className="grid grid-cols-4 items-center mt-2">
                                    <div className="col-span-1"></div>
                                    <label
                                        className="col-span-2 flex justify-center py-2 px-4 border border-primary rounded-md cursor-pointer bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:bg-gray-200"
                                    >
                                        {selectedDocuments.length ? `Selecionado ${selectedDocuments.length} arquivo(s)` : 'Adicionar Documentos'}
                                        <input
                                            type="file"
                                            name="documents"
                                            accept=".pdf, .jpg, .jpeg, .png"
                                            multiple
                                            className="sr-only"
                                            onChange={handleDocumentChange}
                                        />
                                    </label>
                                    {selectedDocuments.length > 0 && (
                                        <button
                                            type="button"
                                            onClick={() => document.getElementsByName('documents')[0].click()}
                                            className=" ml-2 bg-primary text-white rounded-md py-1.5 px-1.5 hover:bg-primary-hover flex items-center justify-center w-2/3 sm:w-1/3 md:w-1/2 lg:w-1/3"
                                        >
                                            <BsPlus className="h-6 w-6" />
                                        </button>
                                    )}
                                </div>

                                {selectedDocuments.length > 0 && (
                                    <div className="flex flex-col items-center mt-2 text-gray-600 overflow-y-auto max-h-28 pt-1">
                                        {selectedDocuments.map((document, index) => (
                                            <span className="mb-1" key={index}>{document.name}</span>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-center">
                                <button type="submit" className="bg-primary hover:bg-primary-hover text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled={isLoading}>
                                    {isLoading ? 'Criando...' : 'Criar projeto'}
                                </button>
                            </div>
                        </form>
                    </div> : <button className="mb-4 addnew-button font-bold" onClick={() => setShowCreateProject(true)}>Adicionar novo projeto</button>}

                    <div className="">
                        {searchQuery && (
                            <>
                                <button className="mb-4 bg-primary rounded-md hover:bg-primary-hover p-2 text-white text-base font-bold" onClick={resetSearch}>Limpar Pesquisa</button>
                                <p className="mb-4 text-base sm:text-xl font-bold">Estes são os resultados da pesquisa:</p>
                            </>
                        )}
                        {projects
                            .filter(project =>
                                project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                project.description.toLowerCase().includes(searchQuery.toLowerCase())
                            ).length === 0 ? (
                            <>
                                <p className="pb-10 font-semibold text-sm sm:text-base">Não foram encontrados resultados para a sua pesquisa</p>
                            </>
                        ) : (
                            projects
                                .filter(project =>
                                    project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                    project.description.toLowerCase().includes(searchQuery.toLowerCase())
                                )
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map(project => (
                                    <div
                                        key={project._id}
                                        className={`my-4 py-5 md:py-8 rounded-md shadow-lg bg-white`}>
                                        <div className="relative mx-5 md:mx-8">
                                            {project.status === "open" && <p className="-mt-2 md:-mt-4 font-bold text-primary text-right">Público</p>}
                                            {project.status === "private" && <p className="-mt-2 md:-mt-4 font-bold text-primary text-right">Privado</p>}
                                            {project.status === "closed" && <p className="-mt-2 md:-mt-4 font-bold text-primary text-right">Terminado</p>}
                                            <div className='flex flex-row items-center mb-4'>
                                                <img src={project.projectPicture} alt="project" className="w-14 h-14 sm:w-16 sm:h-16 object-cover rounded-full" />
                                                <div className='flex flex-col ml-3 justify-between'>
                                                    <h2 className="text-base lg:text-lg font-bold text-left mr-2">{project.title}</h2>
                                                    {isMobile && project.averageRating > 0 &&
                                                        <div className='self-start items-center'>
                                                            <Rating rating={project.averageRating} />
                                                        </div>}
                                                </div>
                                                {!isMobile && project.averageRating > 0 &&
                                                    <div className={`ml-auto self-start'mt-0.5'} flex flex-row items-center`}>
                                                        <Rating rating={project.averageRating} />
                                                    </div>}
                                            </div>
                                            <div className="text-justify text-sm">
                                                <p>
                                                    {showMore[project._id]
                                                        ? project.description
                                                        : project.description.length <= (isMobile ? 120 : 350)
                                                            ? project.description
                                                            : `${project.description.substring(0, isMobile ? 120 : 350)}...`
                                                    }
                                                </p>
                                                {(showMore[project._id] || project.description.length > (isMobile ? 120 : 350)) &&
                                                    <button
                                                        className="text-primary text-sm"
                                                        onClick={() => toggleShowMore(project._id)}
                                                        style={{ display: 'block', margin: '5px auto' }}
                                                    >
                                                        {showMore[project._id] ? 'Mostrar Menos' : 'Mostrar Mais'}
                                                    </button>
                                                }
                                            </div>

                                            <div>
                                                {project.bids && project.bids.length > 0 && project.status === "open" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão a decorrer</p>
                                                    : project.status === "closed" ? <p className="mt-4 text-base md:text-lg font-bold">Leilão fechado</p> : null}
                                                {project.closingTime && project.status != 'closed' && <Countdown closingTime={project.closingTime} status={project.status} id={project._id} />}
                                                <p className="text-black my-2 text-sm md:text-base"><strong>Valor Pretendido:</strong> {project.desiredValue}€</p>
                                            </div>

                                            <div className="flex justify-center align-center mt-4">
                                                {currentAudioProjectId === project._id && isPlaying ? (
                                                    <button className="pause-button font-bold" onClick={() => pauseAudio(project._id)}>
                                                        Pausar áudio
                                                    </button>
                                                ) : (
                                                    <button className="play-button font-bold" onClick={() => playAudio(project._id)}>
                                                        Reproduzir áudio
                                                    </button>
                                                )}
                                                <audio ref={el => (audioRefs.current[project._id] = el)}>
                                                    <source src={project.audioUrl} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                            {currentAudioProjectId === project._id && (
                                                <div className="mt-4 bg-gray-100 w-4/5 md:w-2/5 px-6 pt-6 pb-4 mx-auto rounded-md">
                                                    <div className="relative h-4 rounded-md bg-gray-300 mx-auto">
                                                        <div className="h-full bg-primary rounded-md " style={{ width: `${(currentAudioDuration / audioRefs.current[project._id].duration) * 100}%`, minWidth: '2%' }}></div>
                                                    </div>
                                                    <p className="text-black text-sm mt-2">
                                                        {formatTime(currentAudioDuration)} / {formatTime(audioRefs.current[project._id].duration)}
                                                    </p>
                                                </div>
                                            )}
                                            <div className="flex justify-end"><button
                                                className="px-3 py-3 mt-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                                onClick={() => navigateToMyProjectPage(project)}
                                            >
                                                <AiOutlineArrowRight size={18} />
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectsPage;
